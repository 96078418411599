import React from 'react';
import { useTranslation } from 'react-i18next';

function HomeBanner() {
  const { t } = useTranslation();

  return (
    <div className="relative bg-cover bg-center h-screen flex flex-col justify-center items-center mt-20" 
         style={{ backgroundImage: `url("./images/productivity.jpg")`, backgroundRepeat: 'no-repeat', height:'300px', backgroundSize: 'cover', backgroundPosition: 'center' }}>

      <div className="absolute inset-0 bg-sky-600 opacity-50 dark:bg-black dark:opacity-40"></div>

      <div className="relative z-10 flex justify-between items-center w-full h-full px-10">
        <div className="text-left">
          <h1 className="text-4xl font-bold text-white">{t('GeniusCalendar')}</h1>
          <p className="text-lg text-white mt-2">{t('Commencez à simplifier vos réservations.')}</p>
          <button className="mt-3 md:hidden bg-sky-500 text-white px-6 py-2 rounded-full hover:bg-sky-400">
            Découvrez comment
          </button>
        </div>

        <div className="hidden md:text-right md:block">
          <button className="bg-sky-500 text-white px-6 py-2 rounded-full hover:bg-sky-400">
            Découvrez comment
          </button>
        </div>
      </div>
      


    </div>
  )
}

export default HomeBanner;
