import React from 'react'
import 'flowbite';
import './ContactForm.css'

function ContactForm() {
  return (
    <div><section class=" zlFmyfujKXCLCPyPEOIS mt-10">
    <div class="OmM4wtdsNjVR2r7OSzsm veFXkDzfJN473U3ycrV8 RV8RoaI_SlEMC5CEQ3ms HV01LldvyEqRHHy0hljF IBnFXMOXF1_c25AC5s9x sfxzXnWHolluRGSsbMHw">
        <div class="veFXkDzfJN473U3ycrV8 RV8RoaI_SlEMC5CEQ3ms mDz8RC_zOGy0ceBa3JVu _F_1gdhzusC6tSOWHtx_ sfxzXnWHolluRGSsbMHw _BTfre_DcBxkj7buMlKq UUEOw57fsGfhykSdFAmj">
            <h2 class="_9OKVeTXzfSwD_NYO6_G _Bu4HL5sPDOGN_IKUsOc marR_sCaF_d1KewmkXGX P2kov_od1FPjxxII24T5 g3OYBOqwXUEW4dRGogkH a0Ed69aMSu0vgf4oysz0 dark:text-white">Contactez-Nous</h2>
            <p class="XdjN1uxS_rsa3F90ox40 ErtfuPDAbVhbrEDAXyPl eCx_6PNzncAD5yo7Qcic HUVIJuZ9QnvurQq0NDcX dark:text-gray-200">Besoin d'aide ? Contactez notre équipe de support technique ou de service client en remplissant le formulaire de contact ou en nous appelant au numéro ci-dessous.</p>
        </div>
        <div class="i0EfZzmTLElZVOble53D _i9FbfrBNYoFTPUHnAds _Hd73hs9WJ_nSbZQKC9L NLUM9g1CxiratTbPnE1W">
            <div class="GgmUn_AzvO94fJHuDz7E _BTfre_DcBxkj7buMlKq QTzX2Cr9jhY6WXuEsiB_">
                <form action="#" class="i0EfZzmTLElZVOble53D _i9FbfrBNYoFTPUHnAds MSzSnYVuK0BuLFmWaksd RV8RoaI_SlEMC5CEQ3ms _chPjFVKOPRcMolL9C9r hAFtnIdYDiO6M_67F11P">
                    <div>
                        <label for="first-name" class="ttxtqsLWp2pFRX8yUvWd rD4HtsUG_hahmbh2Kj09 MxG1ClE4KPrIvlL5_Q5x _A6LflweZRUwrcL6M2Tk g3OYBOqwXUEW4dRGogkH _Rz9TooiK_4jTN_Ub8Gm dark:text-white">Prénom</label>
                        <input type="text" id="first-name" class="ttxtqsLWp2pFRX8yUvWd _7ErNxHG5jDLGpANMK93 jCISvWkW5oStPH6Wrb_H MxG1ClE4KPrIvlL5_Q5x g3OYBOqwXUEW4dRGogkH jCHKuJ3G7rklx_LiAfbf _Qk4_E9_iLqcHsRZZ4ge PWreZZgitgAm_Nv4Noh9 T_tFENbpK8DMDNjQRyQa ltPMSn_g3PKyqeS8vmZk focus:ring-primary-500 focus:border-primary-500 _t2wg7hRcyKsNN8CSSeU _BIVSYBXQUqEf_ltPrSk _DJ2tfp6E9c_teMKVD3z a0Ed69aMSu0vgf4oysz0 dark:focus:ring-primary-500 dark:focus:border-primary-500 JsXOfurluQ2aIoxRJf0t" placeholder="Bonnie" required=""/>
                    </div>
                    <div>
                        <label for="last-name" class="ttxtqsLWp2pFRX8yUvWd rD4HtsUG_hahmbh2Kj09 MxG1ClE4KPrIvlL5_Q5x _A6LflweZRUwrcL6M2Tk g3OYBOqwXUEW4dRGogkH _Rz9TooiK_4jTN_Ub8Gm dark:text-white">Nom</label>
                        <input type="text" id="last-name" class="ttxtqsLWp2pFRX8yUvWd _7ErNxHG5jDLGpANMK93 jCISvWkW5oStPH6Wrb_H MxG1ClE4KPrIvlL5_Q5x g3OYBOqwXUEW4dRGogkH jCHKuJ3G7rklx_LiAfbf _Qk4_E9_iLqcHsRZZ4ge PWreZZgitgAm_Nv4Noh9 T_tFENbpK8DMDNjQRyQa ltPMSn_g3PKyqeS8vmZk focus:ring-primary-500 focus:border-primary-500 _t2wg7hRcyKsNN8CSSeU _BIVSYBXQUqEf_ltPrSk _DJ2tfp6E9c_teMKVD3z a0Ed69aMSu0vgf4oysz0 dark:focus:ring-primary-500 dark:focus:border-primary-500 JsXOfurluQ2aIoxRJf0t" placeholder="Green" required=""/>
                    </div>
                    <div>
                        <label for="email" class="ttxtqsLWp2pFRX8yUvWd rD4HtsUG_hahmbh2Kj09 MxG1ClE4KPrIvlL5_Q5x _A6LflweZRUwrcL6M2Tk g3OYBOqwXUEW4dRGogkH _Rz9TooiK_4jTN_Ub8Gm dark:text-white">Email</label>
                        <input type="email" id="email" class="ltPMSn_g3PKyqeS8vmZk jCHKuJ3G7rklx_LiAfbf PWreZZgitgAm_Nv4Noh9 T_tFENbpK8DMDNjQRyQa g3OYBOqwXUEW4dRGogkH MxG1ClE4KPrIvlL5_Q5x _Qk4_E9_iLqcHsRZZ4ge focus:ring-primary-500 focus:border-primary-500 ttxtqsLWp2pFRX8yUvWd jCISvWkW5oStPH6Wrb_H _4wtDMQ2AdJOlYvml5sL _t2wg7hRcyKsNN8CSSeU _BIVSYBXQUqEf_ltPrSk _DJ2tfp6E9c_teMKVD3z a0Ed69aMSu0vgf4oysz0 dark:focus:ring-primary-500 dark:focus:border-primary-500 JsXOfurluQ2aIoxRJf0t" placeholder="prenom@geniuscalendar.com" required=""/>
                    </div>
                    <div>
                        <label for="phone-number" class="ttxtqsLWp2pFRX8yUvWd rD4HtsUG_hahmbh2Kj09 MxG1ClE4KPrIvlL5_Q5x _A6LflweZRUwrcL6M2Tk g3OYBOqwXUEW4dRGogkH _Rz9TooiK_4jTN_Ub8Gm dark:text-white">Numéro de téléphone</label>
                        <input type="number" id="phone-number" class="ttxtqsLWp2pFRX8yUvWd _7ErNxHG5jDLGpANMK93 jCISvWkW5oStPH6Wrb_H MxG1ClE4KPrIvlL5_Q5x g3OYBOqwXUEW4dRGogkH jCHKuJ3G7rklx_LiAfbf _Qk4_E9_iLqcHsRZZ4ge PWreZZgitgAm_Nv4Noh9 T_tFENbpK8DMDNjQRyQa ltPMSn_g3PKyqeS8vmZk focus:ring-primary-500 focus:border-primary-500 _t2wg7hRcyKsNN8CSSeU _BIVSYBXQUqEf_ltPrSk _DJ2tfp6E9c_teMKVD3z a0Ed69aMSu0vgf4oysz0 dark:focus:ring-primary-500 dark:focus:border-primary-500 JsXOfurluQ2aIoxRJf0t" placeholder="0768232055" required=""/>
                    </div>
                    <div class="nqKrWSk_pMaLoiyBLZG5">
                    <label for="subject" class="ttxtqsLWp2pFRX8yUvWd rD4HtsUG_hahmbh2Kj09 MxG1ClE4KPrIvlL5_Q5x _A6LflweZRUwrcL6M2Tk g3OYBOqwXUEW4dRGogkH _Rz9TooiK_4jTN_Ub8Gm dark:text-white">Sujet</label>
                        <input type="text" id="subject" class="ttxtqsLWp2pFRX8yUvWd _7ErNxHG5jDLGpANMK93 jCISvWkW5oStPH6Wrb_H MxG1ClE4KPrIvlL5_Q5x g3OYBOqwXUEW4dRGogkH jCHKuJ3G7rklx_LiAfbf _Qk4_E9_iLqcHsRZZ4ge PWreZZgitgAm_Nv4Noh9 T_tFENbpK8DMDNjQRyQa ltPMSn_g3PKyqeS8vmZk focus:ring-primary-500 focus:border-primary-500 _t2wg7hRcyKsNN8CSSeU _BIVSYBXQUqEf_ltPrSk _DJ2tfp6E9c_teMKVD3z a0Ed69aMSu0vgf4oysz0 dark:focus:ring-primary-500 dark:focus:border-primary-500 JsXOfurluQ2aIoxRJf0t mb-10" placeholder="Votre sujet ici" required=""/>


                        <label for="message" class="ttxtqsLWp2pFRX8yUvWd rD4HtsUG_hahmbh2Kj09 MxG1ClE4KPrIvlL5_Q5x _A6LflweZRUwrcL6M2Tk g3OYBOqwXUEW4dRGogkH eCx_6PNzncAD5yo7Qcic dark:text-white">Message</label>
                        <textarea id="message" rows="6" class="ttxtqsLWp2pFRX8yUvWd _4wtDMQ2AdJOlYvml5sL jCISvWkW5oStPH6Wrb_H MxG1ClE4KPrIvlL5_Q5x g3OYBOqwXUEW4dRGogkH jCHKuJ3G7rklx_LiAfbf _Qk4_E9_iLqcHsRZZ4ge ltPMSn_g3PKyqeS8vmZk PWreZZgitgAm_Nv4Noh9 T_tFENbpK8DMDNjQRyQa focus:ring-primary-500 focus:border-primary-500 _t2wg7hRcyKsNN8CSSeU _BIVSYBXQUqEf_ltPrSk _DJ2tfp6E9c_teMKVD3z a0Ed69aMSu0vgf4oysz0 dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Votre message ici..."></textarea>
                        <p class="KgBTWt39fdiAC__YVNt8 MxG1ClE4KPrIvlL5_Q5x text-gray-400">En soumettant ce formulaire vous acceptez nos <a href="#" class="text-sky-600 hover:text-sky-400  font-semibold ">termes et conditions</a> et notre <a href="#" class=" text-sky-600 font-semibold hover:text-sky-400">politique de confidentialité</a> qui explique comment nous pouvons collecter, utiliser et divulguer vos informations personnelles, y compris à des tiers.</p>
                    </div>
                    <button type="submit" class="_Masco_7wTrd3Tc0qjp9 bFARDnno0HUtfhktTXfR MxG1ClE4KPrIvlL5_Q5x _A6LflweZRUwrcL6M2Tk _F_1gdhzusC6tSOWHtx_ wP9HMsqy6b96l2HBRbgb _Qk4_E9_iLqcHsRZZ4ge bg-primary-700 DbLu21IWZ1QB3nnJtpvP hover:bg-blue-800 _FONMPVaCsLFJJGDaaIL qHIOIw8TObHgD3VvKa5x focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Envoyer <i class="bi bi-send ms-1"></i></button>
                </form>
            </div>
            <div class="i0EfZzmTLElZVOble53D _i9FbfrBNYoFTPUHnAds ErEf06xBWlXPqOC7JqjS MSzSnYVuK0BuLFmWaksd _F_1gdhzusC6tSOWHtx_ hAFtnIdYDiO6M_67F11P VgKB9lhCAwirDjYWyvla">
                <div>
                    <div class="kqgYncRJQ7spwKfig6It _WclR59Ji8jwfmjPtOei neyUwteEn7DOg9pBSJJE RV8RoaI_SlEMC5CEQ3ms _9OKVeTXzfSwD_NYO6_G bt0MUzUgiWUaZjB4iWGh _EiPZYgyUypIYjFtegNh _iRPzRRWy2UNkvZFG8iO _Qk4_E9_iLqcHsRZZ4ge _cpMMPjFQqjJu4i0Puod k9DXNGfIxFLC1UUEto_t o9S02llT8spzjaDWxCbO">
                        <svg class="eUuXwBkW5W4__eatjSfd RRXFBumaW2SHdseZaWm6 ErtfuPDAbVhbrEDAXyPl _dvU7XkD_gq8rV5MWJnx cmyOlpMdB9WCo6ZbMyYL jt7K__cy_iHy7aMDMaLX" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z" clip-rule="evenodd"></path></svg>
                    </div>
                    <p class="rD4HtsUG_hahmbh2Kj09 uyo8h_4Kh1IoUwm8bwJI _WfIfkoGCi0vvUrnNs4M g3OYBOqwXUEW4dRGogkH a0Ed69aMSu0vgf4oysz0 dark:text-white">Information de l'entreprise :</p>
                    <p class="K1PPCJwslha8GUIvV_Cr eCx_6PNzncAD5yo7Qcic dark:text-white"><span className='text-lg font-semibold'>GeniusCalendar</span> <br/>Siret: XXXXXXXX</p>
                </div>
                <div>
                    <div class="kqgYncRJQ7spwKfig6It _WclR59Ji8jwfmjPtOei neyUwteEn7DOg9pBSJJE RV8RoaI_SlEMC5CEQ3ms _9OKVeTXzfSwD_NYO6_G bt0MUzUgiWUaZjB4iWGh _EiPZYgyUypIYjFtegNh _iRPzRRWy2UNkvZFG8iO _Qk4_E9_iLqcHsRZZ4ge _cpMMPjFQqjJu4i0Puod k9DXNGfIxFLC1UUEto_t o9S02llT8spzjaDWxCbO">
                        <svg class="eUuXwBkW5W4__eatjSfd RRXFBumaW2SHdseZaWm6 ErtfuPDAbVhbrEDAXyPl _dvU7XkD_gq8rV5MWJnx cmyOlpMdB9WCo6ZbMyYL jt7K__cy_iHy7aMDMaLX" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd"></path></svg>
                    </div>
                    <p class="rD4HtsUG_hahmbh2Kj09 uyo8h_4Kh1IoUwm8bwJI _WfIfkoGCi0vvUrnNs4M g3OYBOqwXUEW4dRGogkH a0Ed69aMSu0vgf4oysz0 dark:text-white">Adresse:</p>
                    <p class="K1PPCJwslha8GUIvV_Cr eCx_6PNzncAD5yo7Qcic dark:text-white">12, rue du grenouiller <br/> 71200, Le Creusot</p>
                </div>
                <div>
                    <div class="kqgYncRJQ7spwKfig6It _WclR59Ji8jwfmjPtOei neyUwteEn7DOg9pBSJJE RV8RoaI_SlEMC5CEQ3ms _9OKVeTXzfSwD_NYO6_G bt0MUzUgiWUaZjB4iWGh _EiPZYgyUypIYjFtegNh _iRPzRRWy2UNkvZFG8iO _Qk4_E9_iLqcHsRZZ4ge _cpMMPjFQqjJu4i0Puod k9DXNGfIxFLC1UUEto_t o9S02llT8spzjaDWxCbO">
                        <svg class="eUuXwBkW5W4__eatjSfd RRXFBumaW2SHdseZaWm6 ErtfuPDAbVhbrEDAXyPl _dvU7XkD_gq8rV5MWJnx cmyOlpMdB9WCo6ZbMyYL jt7K__cy_iHy7aMDMaLX" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path></svg>
                    </div>
                    <p class="rD4HtsUG_hahmbh2Kj09 uyo8h_4Kh1IoUwm8bwJI _WfIfkoGCi0vvUrnNs4M g3OYBOqwXUEW4dRGogkH a0Ed69aMSu0vgf4oysz0 dark:text-white">Appelez-nous:</p>
                    <p class="cq1ij1EsUw0V2rrqeYEc K1PPCJwslha8GUIvV_Cr eCx_6PNzncAD5yo7Qcic dark:text-white">Appelez-nous pour parler à un membre de notre équipe. Nous sommes toujours heureux d'aider.</p>
                    <p class="LYMps1kO2vF8HBymW3az text-primary-600 dark:text-primary-500 dark:text-white"><a href="tel:0768232055" className='text-sky-400 hover:text-sky-600'>0768232055</a></p>
                </div>
            </div>
        </div>
    </div>
</section></div>
  )
}

export default ContactForm