import React, { useState } from 'react'
import { FaCheck } from 'react-icons/fa';
import 'flowbite'
import './PricingCard.css'
import '../compiled.css'
import { useTranslation } from 'react-i18next';
import '../i18n';
function PricingCardInformatique({ isDarkMode }) {
    const [isMonthly, setIsMonthly] = useState(true);  // state pour gérer le type d'affichage
    const [billPlan, setBillPlan] = useState("monthly");
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;
    console.log(currentLanguage)
    const toggleBillPlan = () => {
        setBillPlan(billPlan === "monthly" ? "annually" : "monthly");
    };
    return (
        <div className="max-w-screen-xl mx-auto">

            {/* <div className="flex items-center justify-center mt-10 space-x-4">
                <span className="text-base font-medium">Mois</span>
                <button
                    onClick={toggleBillPlan}
                    className="relative rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    <div className="w-14 h-6 transition bg-blue-600 rounded-full shadow-md outline-none"></div>
                    <div
                        className={`absolute inline-flex items-center justify-center w-4 h-4 transition-all duration-200 ease-in-out transform bg-white rounded-full shadow-sm top-1 left-1 ${billPlan === "monthly" ? "translate-x-0" : "translate-x-8"
                            }`}
                    ></div>
                </button>
                <span className="text-base font-medium" style={{ position: 'relative' }}>Annuel<span class={`${isMonthly ? 'bg-gray-100 text-gray-600' : 'bg-blue-100 text-blue-600'}  text-xs font-medium mr-2 px-2.5 py-1 dark:bg-blue-900 rounded-full dark:text-blue-300 `} style={{ position: 'absolute', top: -10, right: -120 }}>Éonomisez 20%</span></span>
            </div> */}
            <div className='mb-20 text-center'>
                {/* <div class="la lx ze">
                    <div class="mb yi zx ads aqm avl awc awg awo bbt bbx bce" id="headlessui-radiogroup-5" role="radiogroup" aria-labelledby="headlessui-label-2">
                        <label class="t" id="headlessui-label-2" role="none">Payment frequency</label>
                        <div class="bg-blue-500  bah xq ads are arp" onClick={() => { setIsMonthly(true); console.log('clicked') }} id="headlessui-radiogroup-option-3" role="radio" aria-checked="true" tabindex="0" data-headlessui-state="checked">
                            <span>Mois</span>
                        </div>
                        <div class="axr xq ads are arp" onClick={() => { setIsMonthly(false); console.log('clicked') }} id="headlessui-radiogroup-option-4" role="radio" aria-checked="false" tabindex="-1" data-headlessui-state="">
                            <span>Annuel <span class={`${isMonthly ? 'bg-gray-100 text-gray-600' : 'bg-blue-100 text-blue-600'}  text-xs font-medium mr-2 px-2.5 py-1 dark:bg-blue-900 rounded-full dark:text-blue-300 `}>Éonomisez 20%</span></span>
                        </div>
                    </div>
                </div> */}

                <div className=" lx ze" style={{ position: 'relative', zIndex: 9 }}>
                    <div className="mb yi zx ads aqm avl awc awg awo bbt bbx bce" id="headlessui-radiogroup-5" role="radiogroup" aria-labelledby="headlessui-label-2">
                        <label className="t" id="headlessui-label-2" role="none">Payment frequency</label>
                        <div
                            className={`xq ads are arp transition-all ease-in-out duration-300 ${isMonthly ? 'bg-blue-500 text-white' : ''}`}
                            onClick={() => { setIsMonthly(true); console.log('clicked') }}
                            id="headlessui-radiogroup-option-3"
                            role="radio"
                            aria-checked={isMonthly}
                            tabindex="0"
                        >
                            <span>{t('Mois')}</span>
                        </div>
                        <div 
    className={`xq ads are arp transition-all ease-in-out duration-300 ${isMonthly ? '' : 'bg-blue-500 text-white'}`}
    onClick={() => { setIsMonthly(false); console.log('clicked') }}
    id="headlessui-radiogroup-option-4"
    role="radio"
    aria-checked={!isMonthly}
    tabindex="-1"
>
    <span className='px-3' style={{ position: 'relative' }}>
        {t('Annuel')} 
        <span 
            className={`${isMonthly ? 'bg-gray-100 text-gray-600' : 'bg-blue-100 text-blue-600'} text-xs font-medium mr-2 px-2.5 py-1 rounded-full badge-save`} 
            style={{ position: 'absolute', top: -20, right: currentLanguage === 'en' ? -75 : -115 }}
        >
            {t('Économisez 20%')}
        </span>
    </span>
</div>

                    </div>
                </div>


                {/* <button style={{ position: 'relative', zIndex: 8 }}
                    onClick={() => { setIsMonthly(true); console.log('clicked') }}
                    className={` me-2 mt-20 ${isMonthly ? 'text-blue-600 font-semibold ' : 'gray'}`}
                >
                    Tarifs Mensuel
                </button>
                |

                <button style={{ position: 'relative', zIndex: 8 }}
                    onClick={() => { setIsMonthly(false); console.log('clicked') }}
                    className={` ms-2 ${isMonthly ? 'gray' : 'text-blue-600 font-semibold '}`}
                >
                    Tarifs Annuel <span class={`${isMonthly ? 'bg-gray-100 text-gray-600' : 'bg-blue-100 text-blue-600'}  text-xs font-medium mr-2 px-2.5 py-1 dark:bg-blue-900 rounded-full dark:text-blue-300 `}>Éonomisez 20%</span>
                </button> */}
            </div>
            <div className=' mt-5 flex flex-wrap justify-around  mx-auto'>
                
            <div class={`w-full  ${isDarkMode ? 'shadow shadow-light dark:hover:shadow-light-hover' : ' shadow light:hover:shadow-light-hover '}  transition-shadow duration-300  md:w-1/2 lg:w-1/2 xl:w-1/5 max-w-sm p-4 mb-5 me-3 ms-3 bg-white border border-gray-200 rounded-lg sm:p-8 dark:bg-gray-800 dark:border-gray-700`}>
    <h5 class="mb-4 text-xl font-medium text-gray-500 dark:text-gray-400">{t('Plan de Cybersécurité et Audits')} <br /><small className='text-sm text-gray-400'>{t('Protégez vos actifs numériques avec nos services de cybersécurité complets, incluant des audits, une protection contre les malwares et la mise en place de pare-feux.')} </small></h5>
    <small className='mt-5 text-blue-800 dark:text-blue-500 font-bold'>Nos services incluent :</small>
    <ul role="list" class="space-y-5 mb-7 mt-2">
        <li class="flex space-x-3 items-center">
        <svg class="flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>            <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 lg:text-sm">Audit de sécurité approfondi</span>
        </li>
        <li class="flex space-x-3">
        <svg class="flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>            <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 lg:text-sm">Protection contre les malwares</span>
        </li>
        <li class="flex space-x-3">
        <svg class="flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>            <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 lg:text-sm">Configuration de pare-feux</span>
        </li>
        <li class="flex space-x-3  decoration-gray-500">
        <svg class="flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>            <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 lg:text-sm">Surveillance du réseau</span>
        </li>
        <li class="flex space-x-3  decoration-gray-500">
        <svg class="flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>            <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 lg:text-sm">Tests d'intrusion basiques</span>
        </li>
    </ul>
    <button type="button" class="text-gray-600 dark:text-white border border-gray-200 bg-blue-600 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-200 dark:focus:ring-blue-900 font-medium rounded-lg text-sm px-5 py-2.5 flex justify-center w-50 mx-auto text-center">Choisir ce plan</button>
</div>

<div class={`w-full  ${isDarkMode ? 'shadow shadow-light dark:hover:shadow-light-hover' : ' shadow light:hover:shadow-light-hover '}  transition-shadow duration-300  md:w-1/2 lg:w-1/2 xl:w-1/5 max-w-sm p-4 mb-5 me-3 ms-3 bg-white border border-gray-200 rounded-lg sm:p-8 dark:bg-gray-800 dark:border-gray-700`}>
    <h5 class="mb-4 text-xl font-medium text-gray-500 dark:text-gray-400">{t('Optimisation et Maintenance')} <br /><small className='text-sm text-gray-400'>{t('Améliorez les performances de vos applications et assurez leur bon fonctionnement continu grâce à nos services d\'optimisation et de maintenance.')} </small></h5>
    <small className='mt-5 text-blue-800 dark:text-blue-500 font-bold'>Nos services incluent :</small>
    <ul role="list" class="space-y-5 mb-7 mt-2">
        <li class="flex space-x-3 items-center">
        <svg class="flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>            <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 lg:text-sm">Optimisation des performances</span>
        </li>
        <li class="flex space-x-3">
        <svg class="flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>            <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 lg:text-sm">Mises à jour régulières</span>
        </li>
        <li class="flex space-x-3">
        <svg class="flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>            <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 lg:text-sm">Maintenance préventive</span>
        </li>
        <li class="flex space-x-3  decoration-gray-500">
 <svg class="flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>            <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 lg:text-sm">Support technique</span>
        </li>
        <li class="flex space-x-3  decoration-gray-500">
 <svg class="flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>            <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 lg:text-sm">Sauvegardes régulières</span>
        </li>
    </ul>
    
    <button type="button" class="text-gray-600 dark:text-white border border-gray-200 bg-blue-600 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-200 dark:focus:ring-blue-900 font-medium rounded-lg text-sm px-5 py-2.5 flex justify-center w-50 mx-auto text-center">Choisir ce plan</button>
</div>

<div class={`w-full  ${isDarkMode ? 'shadow light:hover:shadow-professional-plan-hover' : ' shadow light:hover:shadow-professional-plan-hover '}  transition-shadow duration-300   md:w-1/2 lg:w-1/2 xl:w-1/5  max-w-sm p-4 mb-5 me-3 ms-3 bg-white border-2 border-blue-600 rounded-lg sm:p-8 dark:bg-gray-800 dark:border-blue-600`}> 
    <h5 class="mb-4 text-xl font-medium text-blue-500 ">Dépannage Informatique <span class="bg-blue-100 text-blue-600 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">Populaire</span>  <br /><small class='text-xs text-gray-400'>Services de réparation, mise à niveau matérielle et récupération de données pour vos appareils informatiques.</small>
    </h5>
 
    {/* <div class="flex items-baseline mb-5 text-gray-900 dark:text-white">
        <span class="text-5xl font-semibold tracking-tight">XX</span>
        <span class="text-3xl font-semibold">€</span>
        <span class="ml-1 text-xl font-normal text-gray-500 dark:text-gray-400">/service</span>
    </div> */}
   
    <small class='mt-5 text-blue-800 font-bold  dark:text-blue-500'>Nos services incluent :</small>

    <ul role="list" class="space-y-5 mb-7 mt-2">
        <li class="flex space-x-3 items-center">
            <svg class="flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
            </svg>
            <span class="text-sm font-normal leading-tight text-gray-500 dark:text-gray-400 ">Mise à niveau de matériel (RAM, SSD, HDD)</span>
        </li>
        <li class="flex space-x-3">
            <svg class="flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
            </svg>
            <span class="text-sm font-normal leading-tight text-gray-500 dark:text-gray-400 ">Diagnostic matériel complet</span>
        </li>
        <li class="flex space-x-3">
            <svg class="flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
            </svg>
            <span class="text-sm font-normal leading-tight text-gray-500 dark:text-gray-400 ">Récupération de fichiers supprimés</span>
        </li>
        <li class="flex space-x-3 decoration-gray-500">
            <svg class="flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
            </svg>
            <span class="font-normal leading-tight text-gray-500 dark:text-gray-400 text-sm">Maintenance système et optimisation</span>
        </li>
        <li class="flex space-x-3 decoration-gray-500">
            <svg class="flex-shrink-0 alignice w-4 h-4 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
            </svg>
            <div class='flex flex-col'>
                <span class="text-sm font-normal leading-tight text-gray-500 dark:text-gray-400">Suppression de mot de passe</span>
                <small class="block mt-2 bg-blue-100 text-blue-600 p-1 text-xs font-medium text-center w-full rounded dark:bg-blue-900 dark:text-blue-300">Une preuve d'achat à votre nom requise avant toute opération sur le matériel.</small>
            </div>
           
        </li>
        <li class="flex space-x-3 decoration-gray-500">
            <svg class="flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
            </svg>
            <span class="font-normal leading-tight text-gray-500 dark:text-gray-400 text-sm">Assistance technique personnalisée</span>
        </li>
    </ul>
    <button type="button" class="text-gray-600 dark:text-white border border-gray-200 bg-blue-600 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-200 dark:focus:ring-blue-900 font-medium rounded-lg text-sm px-5 py-2.5 flex justify-center w-50 mx-auto text-center">Choisir ce plan</button>
</div>

<div class={`w-full ${isDarkMode ? 'shadow light:hover:shadow-professional-plan-hover' : ' shadow light:hover:shadow-ultimate-plan-hover '}  transition-shadow duration-300   md:w-1/2 lg:w-1/2 xl:w-1/5  max-w-sm p-4 mb-5 me-3  ms-3 bg-white border-2 border-yellow-300 rounded-lg sm:p-8 dark:bg-gray-800 dark:border-yellow-300`}> 
    <h5 class="mb-4 text-xl font-medium text-yellow-500 dark:text-yellow-300 ">Services de Récupération et de Sauvegarde de Données 
    <span class="bg-yellow-100 text-yellow-500 text-xs font-medium ms-2 px-2.5 py-0.5 rounded dark:bg-yellow-200 dark:text-yellow-600">Ultime</span>  <br /><small class='text-xs text-gray-400'>Services de réparation, mise à niveau matérielle et récupération de données pour vos appareils informatiques.</small></h5>
   
    {/* <div class="flex mb-5 items-baseline text-gray-900 dark:text-white">
        <span class="text-5xl font-semibold tracking-tight">{isMonthly ? '20' : '16'}</span>
        <span class="text-3xl font-semibold">€</span>
        <span class="ml-1 text-xl font-normal text-gray-500 dark:text-gray-400">{isMonthly ? '/mois' : '/an'}</span>
    </div> */}
    <small className='mt-5 text-yellow-500 font-bold dark:text-yellow-300'>Nos services incluent :</small>
    <ul role="list" class="space-y-5 mb-7 mt-2">
        <li class="flex space-x-3 items-start">
            <svg class="flex-shrink-0 w-4 h-4 text-yellow-400 dark:text-yellow-400 mt-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.5 9.5 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
            </svg>
            <div class='flex flex-col'>
                <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">Récupération de données supprimées</span>
            </div>
        </li>
        <li class="flex space-x-3 items-start">
        <svg class="flex-shrink-0 w-4 h-4 text-yellow-400 dark:text-yellow-400 mt-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.5 9.5 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
            </svg>
            <div class='flex flex-col'>
                <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">Restauration de partitions perdues</span>
            </div>
        </li>
        <li class="flex space-x-3 items-start">
        <svg class="flex-shrink-0 w-4 h-4 text-yellow-400 dark:text-yellow-400 mt-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.5 9.5 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
            </svg>
            <div class='flex flex-col'>
                <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">Sauvegarde complète du système</span>
            </div>
        </li>
        <li class="flex space-x-3 items-start">
        <svg class="flex-shrink-0 w-4 h-4 text-yellow-400 dark:text-yellow-400 mt-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.5 9.5 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
            </svg>
            <div class='flex flex-col'>
                <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">Clonage de disques et partitions</span>
            </div>
        </li>
        <li class="flex space-x-3 items-start">
        <svg class="flex-shrink-0 w-4 h-4 text-yellow-400 dark:text-yellow-400 mt-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.5 9.5 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
            </svg>
            <div class='flex flex-col'>
                <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">Sauvegarde automatisée et planifiée</span>
            </div>
        </li>
    </ul>
    <button type="button" class="text-gray-600 dark:text-white border border-gray-200 bg-yellow-300 hover:bg-yellow-400 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-200 dark:focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 flex justify-center w-50 mx-auto text-center">Choisir ce plan</button>
</div>


            </div>
        </div>

    )
}

export default PricingCardInformatique