import React, { useState, useEffect } from 'react';
import CarousselInfinite from '../Components/CarousselInfinite';
import Navbar from '../Components/Navbar';
import HeaderBanner from '../Components/HeaderBanner';
import Tabs from '../Components/tabs';
import FeatureCard from '../Components/featuresCard';
import '../App.css'
import '../compiled.css';
import Footer from '../Components/Footer';
import HomeBanner from '../Components/HomeBanner';
import ChatBox from '../Components/ChatBox';
import FeaturesIcons from '../Components/FeaturesIcons';
import { useDarkMode } from '../Components/DarkModeContext'; // Importez le hook personnalisé
import { useLanguage } from '../Components/LanguageContext'; // Importez le hook personnalisé
import { useTranslation } from 'react-i18next';
import '../i18n';

function Home() {
    const { darkMode, toggleDarkMode } = useDarkMode(); // Utilisez l'état global et la fonction toggle
    const { t } = useTranslation();
    // const changeLanguage = (lng) => {
    //   i18n.changeLanguage(lng);
    // };
  // Appliquer la classe 'dark' au body
  useEffect(() => {
    document.title = 'Accueil - Dev Genius';
  }, []);
  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [darkMode]);

  return (
    <div className={`${darkMode ? 'dark' : ''} `}>
    {/* Bouton pour activer/désactiver le mode sombre */}
    {/* <button onClick={() => setDarkMode(!darkMode)}>
      Toggle Dark Mode
    </button> */}
    
  <HeaderBanner isDarkMode={darkMode} showConnectBtn={true} title={t('Titre de la page d\'accueil')} content={t('Sous-titre de la page d\'accueil')} showImage={true} imgSrc="/images/schedule-c.png"/>
  <CarousselInfinite />
  {/* <FeaturesIcons/> */}
  <Tabs isDarkMode={darkMode}/>
  <FeatureCard/>
<HomeBanner/>
<ChatBox isDarkMode={darkMode}/>
  </div>
  )
}

export default Home