import React from 'react'
import '../compiled.css'
function PricingDetails({isDarkMode}) {
  return (
    <div class="  dff">
    <div class="  max-w-screen-2xl gx ark ary cfm ddh ">
        
    <section aria-labelledby="mobile-comparison-heading" class="cvc">
    <h2 id="mobile-comparison-heading" class="t dark:text-white">Feature comparison</h2>
    <div class="gx tv abr">
        <div class="afm aga">
            <div class="agy jf si afo auo clx">
                <h3 class="axv awa awg awp dark:text-white">Basique</h3>
                <p class="ku awa awp axs dark:text-white">Pour les débutant avec un planning de base.</p>
            </div>
            <div class="kw abp">
                <div>
                    <h4 class="awa awg awp axv dark:text-white">Conçu pour les particulierst54O</h4>
                    <div class="ab lk">
                        <div aria-hidden="true" class="aa as de md rg adt alo bbn bxo"></div>
                        <div class="bbt bco ab adt alo bbn cdl cei ciq cis">
                            <dl class="acc acg awa awp">
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Tax Savings</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                            <path
                                                fill-rule="evenodd"
                                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span class="t">Yes</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Easy to use accounting</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                            <path
                                                fill-rule="evenodd"
                                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span class="t">Yes</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Multi-accounts</dt>
                                    <dd class="lx yz zd cbn cfb"><span class="axv">3 accounts</span></dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Invoicing</dt>
                                    <dd class="lx yz zd cbn cfb"><span class="axv">3 invoices</span></dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Exclusive offers</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb axp">
                                            <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
                                        </svg>
                                        <span class="t">No</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">6 months free advisor</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb axp">
                                            <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
                                        </svg>
                                        <span class="t">No</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Mobile and web access</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb axp">
                                            <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
                                        </svg>
                                        <span class="t">No</span>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                        <div aria-hidden="true" class="bbt bco u aa as de md rg adt bxo"></div>
                    </div>
                </div>
                <div>
                    <h4 class="awa awg awp axv dark:text-white">Other perks</h4>
                    <div class="ab lk">
                        <div aria-hidden="true" class="aa as de md rg adt alo bbn bxo"></div>
                        <div class="bbt bco ab adt alo bbn cdl cei ciq cis">
                            <dl class="acc acg awa awp">
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">24/7 customer support</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                            <path
                                                fill-rule="evenodd"
                                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span class="t">Yes</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Instant notifications</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                            <path
                                                fill-rule="evenodd"
                                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span class="t">Yes</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Budgeting tools</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                            <path
                                                fill-rule="evenodd"
                                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span class="t">Yes</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Digital receipts</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                            <path
                                                fill-rule="evenodd"
                                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span class="t">Yes</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Pots to separate money</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb axp">
                                            <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
                                        </svg>
                                        <span class="t">No</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Free bank transfers</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb axp">
                                            <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
                                        </svg>
                                        <span class="t">No</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Business debit card</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb axp">
                                            <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
                                        </svg>
                                        <span class="t">No</span>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                        <div aria-hidden="true" class="bbt bco u aa as de md rg adt bxo"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="afm aga">
            <div class="agy jf si afo auo clx">
                <h3 class="axv awa awg awp dark:text-white">Essentiel</h3>
                <p class="ku awa awp axs dark:text-white">All your essential business finances, taken care of.</p>
            </div>
            <div class="kw abp">
                <div>
                    <h4 class="awa awg awp axv dark:text-white">Catered for business</h4>
                    <div class="ab lk">
                        <div aria-hidden="true" class="aa as de md rg adt alo bbn bxo"></div>
                        <div class="bbt bco ab adt alo bbn cdl cei ciq cis">
                            <dl class="acc acg awa awp">
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Tax Savings</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                            <path
                                                fill-rule="evenodd"
                                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span class="t">Yes</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Easy to use accounting</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                            <path
                                                fill-rule="evenodd"
                                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span class="t">Yes</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Multi-accounts</dt>
                                    <dd class="lx yz zd cbn cfb"><span class="axv">3 accounts</span></dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Invoicing</dt>
                                    <dd class="lx yz zd cbn cfb"><span class="axv">3 invoices</span></dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Exclusive offers</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb axp">
                                            <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
                                        </svg>
                                        <span class="t">No</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">6 months free advisor</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb axp">
                                            <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
                                        </svg>
                                        <span class="t">No</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Mobile and web access</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb axp">
                                            <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
                                        </svg>
                                        <span class="t">No</span>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                        <div aria-hidden="true" class="bbt bco u aa as de md rg adt bxo"></div>
                    </div>
                </div>
                <div>
                    <h4 class="awa awg awp axv dark:text-white">Other perks</h4>
                    <div class="ab lk">
                        <div aria-hidden="true" class="aa as de md rg adt alo bbn bxo"></div>
                        <div class="bbt bco ab adt alo bbn cdl cei ciq cis">
                            <dl class="acc acg awa awp">
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">24/7 customer support</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                            <path
                                                fill-rule="evenodd"
                                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span class="t">Yes</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Instant notifications</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                            <path
                                                fill-rule="evenodd"
                                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span class="t">Yes</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Budgeting tools</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                            <path
                                                fill-rule="evenodd"
                                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span class="t">Yes</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Digital receipts</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                            <path
                                                fill-rule="evenodd"
                                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span class="t">Yes</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Pots to separate money</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb axp">
                                            <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
                                        </svg>
                                        <span class="t">No</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Free bank transfers</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb axp">
                                            <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
                                        </svg>
                                        <span class="t">No</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Business debit card</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb axp">
                                            <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
                                        </svg>
                                        <span class="t">No</span>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                        <div aria-hidden="true" class="bbt bco u aa as de md rg adt bxo"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="afm aga">
            <div class="border-blue-600 jf si afo auo clx">
                <h3 class="text-blue-600 awa awg awp">Professionnel</h3>
                <p class="ku awa awp axs dark:text-white">The best financial services for your thriving business.</p>
            </div>
            <div class="kw abp">
                <div>
                    <h4 class="awa awg awp axv dark:text-white">Catered for business</h4>
                    <div class="ab lk">
                        <div aria-hidden="true" class="aa as de md rg adt alo bbn bxo"></div>
                        <div class="border-blue-600 sm:border-0 border-2 bcy ab adt alo bbn cdl cei ciq cis">
                            <dl class="acc acg awa awp">
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Tax Savings</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                            <path
                                                fill-rule="evenodd"
                                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span class="t">Yes</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Easy to use accounting</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                            <path
                                                fill-rule="evenodd"
                                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span class="t">Yes</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Multi-accounts</dt>
                                    <dd class="lx yz zd cbn cfb"><span class="awg ayh">Unlimited accounts</span></dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Invoicing</dt>
                                    <dd class="lx yz zd cbn cfb"><span class="awg ayh">Unlimited invoices</span></dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Exclusive offers</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                            <path
                                                fill-rule="evenodd"
                                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span class="t">Yes</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">6 months free advisor</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                            <path
                                                fill-rule="evenodd"
                                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span class="t">Yes</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Mobile and web access</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                            <path
                                                fill-rule="evenodd"
                                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span class="t">Yes</span>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                        <div aria-hidden="true" class="border-blue-600  border-2 rounded bcy u aa as de md rg adt bxo"></div>
                    </div>
                </div>
                <div>
                    <h4 class="awa awg awp axv dark:text-white">Other perks</h4>
                    <div class="ab lk">
                        <div aria-hidden="true" class="aa as de md rg adt alo bbn bxo"></div>
                        <div class="border-blue-600 border-2 sm:border-0 bcy ab adt alo bbn cdl cei ciq cis">
                            <dl class="acc acg awa awp">
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">24/7 customer support</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                            <path
                                                fill-rule="evenodd"
                                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span class="t">Yes</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Instant notifications</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                            <path
                                                fill-rule="evenodd"
                                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span class="t">Yes</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Budgeting tools</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                            <path
                                                fill-rule="evenodd"
                                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span class="t">Yes</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Digital receipts</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                            <path
                                                fill-rule="evenodd"
                                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span class="t">Yes</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Pots to separate money</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                            <path
                                                fill-rule="evenodd"
                                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span class="t">Yes</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Free bank transfers</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                            <path
                                                fill-rule="evenodd"
                                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span class="t">Yes</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Business debit card</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                            <path
                                                fill-rule="evenodd"
                                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span class="t">Yes</span>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                        <div aria-hidden="true" class="border-blue-600 border-2 bcy u aa as de md rg adt bxo"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="afm aga">
            <div class="agy jf si afo auo clx">
                <h3 class="axv awa awg awp dark:text-white">Ultimate</h3>
                <p class="ku awa awp axs dark:text-white">Convenient features to take your business to the next level.</p>
            </div>
            <div class="kw abp">
                <div>
                    <h4 class="awa awg awp axv dark:text-white">Catered for business</h4>
                    <div class="ab lk">
                        <div aria-hidden="true" class="aa as de md rg adt alo bbn bxo"></div>
                        <div class="bbt bco ab adt alo bbn cdl cei ciq cis">
                            <dl class="acc acg awa awp">
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Tax Savings</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                            <path
                                                fill-rule="evenodd"
                                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span class="t">Yes</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Easy to use accounting</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                            <path
                                                fill-rule="evenodd"
                                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span class="t">Yes</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Multi-accounts</dt>
                                    <dd class="lx yz zd cbn cfb"><span class="axv">7 accounts</span></dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Invoicing</dt>
                                    <dd class="lx yz zd cbn cfb"><span class="axv">10 invoices</span></dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Exclusive offers</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                            <path
                                                fill-rule="evenodd"
                                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span class="t">Yes</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">6 months free advisor</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                            <path
                                                fill-rule="evenodd"
                                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span class="t">Yes</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Mobile and web access</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb axp">
                                            <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
                                        </svg>
                                        <span class="t">No</span>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                        <div aria-hidden="true" class="bbt bco u aa as de md rg adt bxo"></div>
                    </div>
                </div>
                <div>
                    <h4 class="awa awg awp axv dark:text-white">Other perks</h4>
                    <div class="ab lk">
                        <div aria-hidden="true" class="aa as de md rg adt alo bbn bxo"></div>
                        <div class="bbt bco ab adt alo bbn cdl cei ciq cis">
                            <dl class="acc acg awa awp">
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">24/7 customer support</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                            <path
                                                fill-rule="evenodd"
                                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span class="t">Yes</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Instant notifications</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                            <path
                                                fill-rule="evenodd"
                                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span class="t">Yes</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Budgeting tools</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                            <path
                                                fill-rule="evenodd"
                                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span class="t">Yes</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Digital receipts</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                            <path
                                                fill-rule="evenodd"
                                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span class="t">Yes</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Pots to separate money</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                            <path
                                                fill-rule="evenodd"
                                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span class="t">Yes</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Free bank transfers</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb axp">
                                            <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
                                        </svg>
                                        <span class="t">No</span>
                                    </dd>
                                </div>
                                <div class="lx yz zf ari arz bxu cat ces">
                                    <dt class="aue axs sm:dark:text-white">Business debit card</dt>
                                    <dd class="lx yz zd cbn cfb">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb axp">
                                            <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
                                        </svg>
                                        <span class="t">No</span>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                        <div aria-hidden="true" class="bbt bco u aa as de md rg adt bxo"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

        
        <section aria-labelledby="comparison-heading" class="md cuw">
            <h2 id="comparison-heading" class="t">Feature comparison</h2>
            <div class="mb yk aaj afm aga bgl">
                <div aria-hidden="true" class="jf">
                    <div class="agy afo auo">
                        <p class="axv awa awg awp dark:text-white">Basique</p>
                        <p class="ku awa awp axs dark:text-white">Pour les débutant avec un planning de base.</p>
                    </div>
                </div>
                <div aria-hidden="true" class="jf">
                    <div class="agy afo auo">
                        <p class="axv awa awg awp dark:text-white">Essentiel</p>
                        <p class="ku awa awp axs dark:text-white">Pour les personnes ayant des besoins de planification plus sophistiqués.</p>
                    </div>
                </div>
                <div aria-hidden="true" class="jf">
                    <div class="border-blue-600 afo auo">
                        <p class="text-blue-600 awa awg awp ">Professionnel</p>
                        <p class="ku awa awp axs dark:text-white">Pour les petites équipes qui ont besoin d'une plus grande personnalisation et de rapports.</p>
                    </div>
                </div>
                <div aria-hidden="true" class="jf">
                    <div class="agy afo auo">
                        <p class="axv awa awg awp dark:text-white">Ultimate</p>
                        <p class="ku awa  awp axs dark:text-white">Plus de fonctionnalités essentielles.</p>
                    </div>
                </div>
              
            </div>
            <div class="ja abr">
                <div>
                    <h3 class="awa awg awp axv dark:text-white">Catered for business</h3>
                    <div class="ab gf kw">
                        <div class="aa aq as mb yk aaj bgl" aria-hidden="true">
                            {/* <div class="bg-white dark:bg-gray-500 adt"></div>
                            <div class="bg-white dark:bg-gray-500 adt"></div>
                            <div class="bg-white dark:bg-gray-500 adt"></div>
                            <div class="bg-white dark:bg-gray-500 adt"></div> */}
                            <div class="ph tn adt alo bx-shadow "></div>
                            <div class="ph tn adt alo bx-shadow"></div>
                            <div class={`ph tn adt alo ${isDarkMode ?'bx-shadow-blue' : 'bx-shadow'}`}></div>
                            <div class="ph tn adt alo bx-shadow"></div>
                        </div>
                        <table class="ab tn uv ux ">
                            <thead>
                                <tr class="avk">
                                    <th scope="col"><span class="t">Fonctionnalité</span></th>
                                    <th scope="col"><span class="t">Basique</span></th>
                                    <th scope="col"><span class="t">Essentiel</span></th>
                                    <th scope="col"><span class="t">Professionnel</span></th>
                                    <th scope="col"><span class="t">Ultimate</span></th>
                             
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row" class="rh arz aue avk awa awf awp axv dark:text-white">
                                        Tax Savings
                                        <div class="aa aq lf pi ail"></div>
                                    </th>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#0099e1" aria-hidden="true" class="gx nz sb ayh">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                            <span class="t">Yes</span>
                                        </span>
                                    </td>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#0099e1" aria-hidden="true" class="gx nz sb ayh">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                            <span class="t">Yes</span>
                                        </span>
                                    </td>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#1c64f2" aria-hidden="true" class="gx nz sb ayh">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                            <span class="t">Yes</span>
                                        </span>
                                    </td>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#1c64f2" aria-hidden="true" class="gx nz sb ayh">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                            <span class="t">Yes</span>
                                        </span>
                                    </td>
                                    
                                
                                </tr>
                                <tr>
                                    <th scope="row" class="rh arz aue avk awa awf awp axv dark:text-white">
                                        Easy to use accounting
                                        <div class="aa aq lf pi ail"></div>
                                    </th>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                            <span class="t">Yes</span>
                                        </span>
                                    </td>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                            <span class="t">Yes</span>
                                        </span>
                                    </td>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                            <span class="t">Yes</span>
                                        </span>
                                    </td>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                            <span class="t">Yes</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr className=''>
                                    <th scope="row" class=" arz aue avk awa awf awp axv dark:text-white">
                                        Multi-accounts
                                        <div class="aa aq lf pi ail"></div>
                                    </th>
                                    <td class="ab  ari arn ">
                                        <span class="ab ph tn arz"><span class="axv awa awp">3 accounts</span></span>
                                    </td>
                                    <td class="ab  ari arn ">
                                        <span class="ab ph tn arz"><span class="axv awa awp">3 accounts</span></span>
                                    </td>
                                    <td class="ab  ari arn ">
                                        <span class="ab ph tn arz"><span class="awg ayh awa awp">Unlimited accounts</span></span>
                                    </td>
                                    <td class="ab  ari arn ">
                                        <span class="ab ph tn arz"><span class="awg ayh awa awp">Unlimited accounts</span></span>
                                    </td>
                                   
                                </tr>
                                <tr>
                                    <th scope="row" class="rh arz aue avk awa awf awp axv dark:text-white">
                                        Invoicing
                                        <div class="aa aq lf pi ail"></div>
                                    </th>
                                    <td class="ab rh ari arn ">
                                        <span class="ab ph tn arz"><span class="axv awa awp">3 invoices</span></span>
                                    </td>
                                    <td class="ab rh ari arn ">
                                        <span class="ab ph tn arz"><span class="axv awa awp">3 invoices</span></span>
                                    </td>
                                    <td class="ab rh ari arn ">
                                        <span class="ab ph tn arz"><span class="awg ayh awa awp">Unlimited invoices</span></span>
                                    </td>
                                    <td class="ab rh ari arn ">
                                        <span class="ab ph tn arz"><span class="awg ayh awa awp">Unlimited invoices</span></span>
                                    </td>
                                    
                                </tr>
                                <tr>
                                    <th scope="row" class="rh arz aue avk awa awf awp axv dark:text-white">
                                        Exclusive offers
                                        <div class="aa aq lf pi ail"></div>
                                    </th>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx  nz sb axp">
                                                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
                                            </svg>
                                            <span class="t">No</span>
                                        </span>
                                    </td>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx  nz sb axp">
                                                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
                                            </svg>
                                            <span class="t">No</span>
                                        </span>
                                    </td>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx  nz sb ayh">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                            <span class="t">Yes</span>
                                        </span>
                                    </td>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx  nz sb ayh">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                            <span class="t">Yes</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" class="rh arz aue avk awa awf awp axv dark:text-white">
                                        6 months free advisor
                                        <div class="aa aq lf pi ail"></div>
                                    </th>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx  nz sb axp">
                                                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
                                            </svg>
                                            <span class="t">No</span>
                                        </span>
                                    </td>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx  nz sb axp">
                                                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
                                            </svg>
                                            <span class="t">No</span>
                                        </span>
                                    </td>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx  nz sb ayh">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                            <span class="t">Yes</span>
                                        </span>
                                    </td>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx  nz sb ayh">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                            <span class="t">Yes</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" class="rh arz aue avk awa awf awp axv dark:text-white">Mobile and web access</th>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class=" gx nz sb axp">
                                                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
                                            </svg>
                                            <span class="t">No</span>
                                        </span>
                                    </td>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class=" gx nz sb ayh">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                            <span class="t">Yes</span>
                                        </span>
                                    </td>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx  nz sb ayh">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                            <span class="t">Yes</span>
                                        </span>
                                    </td>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx  nz sb axp">
                                                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
                                            </svg>
                                            <span class="t">No</span>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="u aa aq as mb yk aaj bgl" aria-hidden="true">
                            <div class="bbt bco adt"></div>
                            <div class="bbt bco adt"></div>
                            <div class="border border-2 border-blue-600 bcy adt"></div>
                            <div class="bbt bco adt"></div>
                        </div>
                    </div>
                </div>
                <div>
                    <h3 class="awa awg awp axv dark:text-white">Other perks</h3>
                    <div class="ab gf kw">
                        <div class="aa aq as mb yk aaj bgl" aria-hidden="true">
                            <div class="ph tn adt alo bx-shadow"></div>
                            <div class="ph tn adt alo bx-shadow"></div>
                            <div class={`ph tn adt alo  ${isDarkMode ? 'bx-shadow-blue' : 'bx-shadow'}`}></div>
                            <div class="ph tn adt alo bx-shadow"></div>
                        </div>
                        <table class="ab tn uv ux">
                            <thead>
                                <tr class="avk">
                                    <th scope="col"><span class="t">Feature</span></th>
                                    <th scope="col"><span class="t">Starter tier</span></th>
                                    <th scope="col"><span class="t">Growth tier</span></th>
                                    <th scope="col"><span class="t">Scale tier</span></th>
                                    <th scope="col"><span class="t">Growth tier</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row" class="rh arz aue avk awa awf awp axv dark:text-white">
                                        24/7 customer support
                                        <div class="aa aq lf pi ail"></div>
                                    </th>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class=" gx nz sb ayh">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                            <span class="t">Yes</span>
                                        </span>
                                    </td>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx  nz sb ayh">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                            <span class="t">Yes</span>
                                        </span>
                                    </td>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx  nz sb ayh">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                            <span class="t">Yes</span>
                                        </span>
                                    </td>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx  nz sb ayh">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                            <span class="t">Yes</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" class="rh arz aue avk awa awf awp axv dark:text-white">
                                        Instant notifications
                                        <div class="aa aq lf pi ail"></div>
                                    </th>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class=" gx nz sb ayh">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                            <span class="t">Yes</span>
                                        </span>
                                    </td>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class=" gx nz sb ayh">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                            <span class="t">Yes</span>
                                        </span>
                                    </td>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class=" gx nz sb ayh">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                            <span class="t">Yes</span>
                                        </span>
                                    </td>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class=" gx nz sb ayh">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                            <span class="t">Yes</span>
                                        </span>
                                    </td>
                                    
                                </tr>
                                <tr>
                                    <th scope="row" class="rh arz aue avk awa awf awp axv dark:text-white">
                                        Budgeting tools
                                        <div class="aa aq lf pi ail"></div>
                                    </th>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class=" gx nz sb ayh">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                            <span class="t">Yes</span>
                                        </span>
                                    </td>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx  nz sb ayh">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                            <span class="t">Yes</span>
                                        </span>
                                    </td>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx  nz sb ayh">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                            <span class="t">Yes</span>
                                        </span>
                                    </td>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx  nz sb ayh">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                            <span class="t">Yes</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" class="rh arz aue avk awa awf awp axv dark:text-white">
                                        Digital receipts
                                        <div class="aa aq lf pi ail"></div>
                                    </th>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx  nz sb ayh">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                            <span class="t">Yes</span>
                                        </span>
                                    </td>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx  nz sb ayh">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                            <span class="t">Yes</span>
                                        </span>
                                    </td>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx  nz sb ayh">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                            <span class="t">Yes</span>
                                        </span>
                                    </td>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx  nz sb ayh">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                            <span class="t">Yes</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" class="rh arz aue avk awa awf awp axv dark:text-white">
                                        Pots to separate money
                                        <div class="aa aq lf pi ail"></div>
                                    </th>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class=" gx nz sb axp">
                                                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
                                            </svg>
                                            <span class="t">No</span>
                                        </span>
                                    </td>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx  nz sb ayh">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                            <span class="t">Yes</span>
                                        </span>
                                    </td>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx  nz sb ayh">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                            <span class="t">Yes</span>
                                        </span>
                                    </td>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx  nz sb ayh">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                            <span class="t">Yes</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" class="rh arz aue avk awa awf awp axv dark:text-white">
                                        Free bank transfers
                                        <div class="aa aq lf pi ail"></div>
                                    </th>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx  nz sb axp">
                                                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
                                            </svg>
                                            <span class="t">No</span>
                                        </span>
                                    </td>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx  nz sb ayh">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                            <span class="t">Yes</span>
                                        </span>
                                    </td>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx  nz sb axp">
                                                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
                                            </svg>
                                            <span class="t">No</span>
                                        </span>
                                    </td>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx  nz sb ayh">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                            <span class="t">Yes</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" class="rh arz aue avk awa awf awp axv dark:text-white">Business debit card</th>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx  nz sb axp">
                                                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
                                            </svg>
                                            <span class="t">No</span>
                                        </span>
                                    </td>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class=" gx nz sb ayh">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                            <span class="t">Yes</span>
                                        </span>
                                    </td>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class=" gx nz sb axp">
                                                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
                                            </svg>
                                            <span class="t">No</span>
                                        </span>
                                    </td>
                                    <td class="ab rh ari arn avl">
                                        <span class="ab ph tn arz">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class=" gx nz sb ayh">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                            <span class="t">Yes</span>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="u aa aq as mb yk aaj bgl" aria-hidden="true">
                            <div class="bbt bco adt"></div>
                            <div class="bbt bco adt"></div>
                            <div class="border border-2 border-blue-600 bcy adt"></div>
                            <div class="bbt bco adt"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>

  )
}

export default PricingDetails