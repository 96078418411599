import React from 'react';
import '../bootstrap-icons.css'
import '../boxicons.min.css'
import './featuresCard.css'
import { useTranslation } from 'react-i18next';

function FeaturesCard() {
    const { t } = useTranslation();

    return (
        <div className="container max-w-screen-lg mx-auto">
            <h2 className='text-3xl mt-20 mb-20 text-center font-semibold'>Des solutions innovantes pour optimiser votre entreprise</h2>
            <div className="flex flex-wrap justify-center items-center px-10">
                
                {/* Fonctionnalité : Gestion Intelligente des Projets */}
                <div className="flex flex-col md:flex-row w-full justify-center items-center mb-6 md:mb-0">
                    <div className="w-full md:w-1/2">
                        <dotlottie-player src="https://lottie.host/f010e6fc-4bc6-4c2f-85df-1ed61e9444a9/ALEHrIbxVT.json" background="transparent" speed="1" style={{ width: '300px', height: '300px', position: 'relative' }} loop autoplay></dotlottie-player>
                    </div>
                    <div className="w-full md:w-1/2">
                        <h3 className='text-3xl font-semibold'>Conception et Développement de Tableaux de Bord sur Mesure
                        </h3>
                        <p>Nous développons des tableaux de bord sur mesure et des applications de gestion qui s'adaptent parfaitement à vos processus d'affaires. Notre approche personnalisée permet une gestion efficace des tâches, des délais, et des ressources, maximisant ainsi la productivité et améliorant le contrôle de vos opérations. Découvrez comment nos solutions peuvent transformer la gestion de vos projets.</p>
                        <button className='rounded-full shadow hover:shadow-xl bg-cyan-400 text-white px-4 py-1 mt-3 transition duration-150 ease-in-out hover:bg-cyan-500'>
                            En savoir plus
                        </button>
                    </div>
                </div>

                {/* Fonctionnalité : Intégration Personnalisée */}
                <div className="flex flex-col-reverse md:flex-row w-full justify-center items-center mb-6 md:mb-20 md:mt-20">
                    <div className="w-full lg:w-1/2">
                        <h3 className='text-3xl font-semibold'>Solutions de Gestion Intégrées
                        </h3>
                        <p>Nos développeurs conçoivent des solutions de gestion intégrées qui s'harmonisent parfaitement avec votre écosystème existant. Créez des applications qui répondent aux besoins spécifiques de votre entreprise, en offrant à vos équipes et clients un accès rapide et sécurisé aux informations vitales, tout en améliorant l'efficacité opérationnelle et la réactivité organisationnelle.</p>
                        <button className='rounded-full shadow hover:shadow-xl bg-cyan-400 text-white px-4 py-1 mt-3 transition duration-150 ease-in-out hover:bg-cyan-500'>
                            En savoir plus
                        </button>
                    </div>
                    <div className="w-full lg:w-1/2">
                    <dotlottie-player src="https://lottie.host/5de7d86a-e521-434e-92bd-18eec6d78fe9/9BSiFaBhvu.json" background="transparent" speed="1" style={{width: '300px', height: '300px'}} loop autoplay></dotlottie-player>
                    </div>
                </div>

                {/* Fonctionnalité : Suivi et Analyse en Temps Réel */}
                <div className="flex flex-col md:flex-row w-full justify-center items-center mb-6 md:mb-0">
                    <div className="w-full md:w-1/2">
                    <dotlottie-player src="https://lottie.host/b5dec069-dbda-485e-a9f9-1b3b82757a25/7UbBerzhpm.json" background="transparent" speed="1" style={{width: '300px', height: '300px'}} loop autoplay></dotlottie-player>
                    </div>
                    <div className="w-full md:w-1/2">
                        <h3 className='text-3xl font-semibold'>Suivi et Analyse en Temps Réel</h3>
                        <p>Accédez à des données en temps réel pour une prise de décision éclairée. Surveillez les indicateurs de performance, optimisez les workflows et identifiez les opportunités d'amélioration grâce à nos outils d'analyse avancés.</p>
                        <button className='rounded-full shadow hover:shadow-xl bg-cyan-400 text-white px-4 py-1 mt-3 transition duration-150 ease-in-out hover:bg-cyan-500'>
                            En savoir plus
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FeaturesCard;
