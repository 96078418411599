import React, { useState, useEffect } from 'react';
import CarousselInfinite from '../Components/CarousselInfinite';
import Navbar from '../Components/Navbar';
import HeaderBanner from '../Components/HeaderBanner';
import Tabs from '../Components/tabs';
import FeatureCard from '../Components/featuresCard';
import '../App.css'
import '../compiled.css';
import Footer from '../Components/Footer';
import HomeBanner from '../Components/HomeBanner';
import ChatBox from '../Components/ChatBox';
import FeaturesIcons from '../Components/FeaturesIcons';
import { useDarkMode } from '../Components/DarkModeContext'; // Importez le hook personnalisé
import PricingCard from '../Components/PricingCard';
import PricingDetails from '../Components/PricingDetails';
import { useTranslation } from 'react-i18next';

function Price() {
  const { t } = useTranslation();

    const { darkMode, toggleDarkMode } = useDarkMode(); // Utilisez l'état global et la fonction toggle
    useEffect(() => {
      document.title = 'Prix - GeniusCalendar';
    }, []);
    // Appliquer la classe 'dark' au body
    useEffect(() => {
      if (darkMode) {
        document.documentElement.classList.add('dark')
      } else {
        document.documentElement.classList.remove('dark')
      }
    }, [darkMode]);
  return (
    <div className={`${darkMode ? 'dark' : ''} `}>
    {/* Bouton pour activer/désactiver le mode sombre */}
    {/* <button onClick={() => setDarkMode(!darkMode)}>
      Toggle Dark Mode
    </button> */}

  <HeaderBanner isDarkMode={darkMode} showConnectBtn={false} title={t(('Choississez le plan parfait adapté à vos besoins'))} content={t("Un calendrier conçu pour maximiser votre productivité et simplifier votre planification quotidienne. Chaque fonction a été pensée pour vous offrir une expérience utilisateur sans pareil.")} showImage={false}/>
  <CarousselInfinite />
  <PricingCard isDarkMode={darkMode}/>
  <PricingDetails isDarkMode={darkMode}/>
  {/* <FeaturesIcons/> */}
  {/* <Tabs isDarkMode={darkMode}/> */}
  <FeatureCard/>
<HomeBanner/>
<ChatBox isDarkMode={darkMode}/>
 
  </div>
  )
}

export default Price