import React, { useState, useEffect } from 'react';
import CarousselInfinite from '../Components/CarousselInfinite';
import Navbar from '../Components/Navbar';
import HeaderBanner from '../Components/HeaderBanner';
import Tabs from '../Components/tabs';
import FeatureCard from '../Components/featuresCard';
import '../App.css'
import '../compiled.css';
import Footer from '../Components/Footer';
import HomeBanner from '../Components/HomeBanner';
import ChatBox from '../Components/ChatBox';
import FeaturesIcons from '../Components/FeaturesIcons';
import { useDarkMode } from '../Components/DarkModeContext'; // Importez le hook personnalisé
import ContactForm from '../Components/ContactForm';

function Contact() {

    const { darkMode, toggleDarkMode } = useDarkMode(); // Utilisez l'état global et la fonction toggle
    useEffect(() => {
      document.title = 'Contact - GeniusCalendar';
    }, []);
    // Appliquer la classe 'dark' au body
    useEffect(() => {
      if (darkMode) {
        document.documentElement.classList.add('dark')
      } else {
        document.documentElement.classList.remove('dark')
      }
    }, [darkMode]);
  return (
    <div className={`${darkMode ? 'dark' : ''} `}>
    {/* Bouton pour activer/désactiver le mode sombre */}
    {/* <button onClick={() => setDarkMode(!darkMode)}>
      Toggle Dark Mode
    </button> */}
   
  <HeaderBanner isDarkMode={darkMode} showConnectBtn={true} title="Contactez-Nous" content="Si vous avez des questions, des préoccupations ou des suggestions, n'hésitez pas à nous contacter via le formulaire ci-dessous, par e-mail ou par téléphone. Nous nous efforçons de répondre dans les 24 heures." showImage={false}/>
  <CarousselInfinite />
  <ContactForm/>
  {/* <FeaturesIcons/> */}
  {/* <Tabs isDarkMode={darkMode}/> */}
  <FeatureCard/>
<HomeBanner/>
<ChatBox isDarkMode={darkMode}/>
 
  </div>
  )
}

export default Contact