import {
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Heading,
    Text,
  } from "@chakra-ui/react";
  import React, { useState, useRef, useEffect } from "react";
  import smoothScrollIntoView from "smooth-scroll-into-view-if-needed";
  import { FaAppStoreIos, FaAssistiveListeningSystems, FaDatabase, FaDesktop, FaLaptop, FaMobileAlt, FaPaintBrush, FaShieldAlt, FaSitemap, FaToolbox, FaTools, FaUnlock } from "react-icons/fa";
  import { SiAdobecreativecloud } from "react-icons/si";

  import "./tabs.css";
  
  function Tabpage({ isDarkMode }) {
    const [activeTab, setActiveTab] = useState(0);
    const tabListRef = useRef(null);
  
    const scrollToTab = (index) => {
      const tabNodes = tabListRef.current.childNodes;
      if (tabNodes[index]) {
        smoothScrollIntoView(tabNodes[index], {
          scrollMode: "always",
          block: "center",
          inline: "center",
        });
      }
    };
  
    useEffect(() => {
      if (tabListRef.current) {
        const tabNodes = Array.from(tabListRef.current.children);
        const selectedTab = tabNodes[activeTab];
  
        if (selectedTab) {
          const left =
            selectedTab.offsetLeft -
            tabListRef.current.offsetWidth / 2 +
            selectedTab.offsetWidth / 2;
          tabListRef.current.scrollTo({
            left: left,
            behavior: "smooth",
          });
        }
      }
    }, [activeTab]);
  
    const handleTabClick = (e, index) => {
      e.preventDefault();
      e.stopPropagation();
      setActiveTab(index);
      scrollToTab(index);
    };
  
    const tabs = [
      { label: "Développement Web & Mobile", icon: <FaDesktop /> },
      { label: "Applications Mobiles", icon: <FaMobileAlt /> },
      { label: "Conception Graphique", icon: <FaPaintBrush /> },
      { label: "Dépannage Informatique", icon: <FaTools /> },
      { label: "Suppression de mots de passe", icon: <FaUnlock /> },
    ];
  
    return (
      <div className="mx-auto max-w-screen-lg px-5">
        <h2 className="text-3xl font-bold text-center mb-9 mt-20">
          Découvrez nos services 
        </h2>
        <Tabs
          variant="enclosed"
          index={activeTab}
          onChange={setActiveTab}
          isLazy
          className="mx-auto text-center justify-center items-center"
        >
          <TabList
            ref={tabListRef}
            className="hide-scrollbar"
            style={{
              whiteSpace: "nowrap",
              overflowX: "auto",
              display: "block",
            }}
          >
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                _selected={{
                  borderBottom: "2px solid rgb(0, 107, 255)",
                  color: "rgb(0, 107, 255)",
                  backgroundColor: isDarkMode ? "" : "rgb(247, 250, 255)",
                }}
                onClick={(e) => handleTabClick(e, index)}
                style={{
                  display: "inline-block",
                  textAlign: "center",
                  padding: "15px",
                  color: activeTab === index ? "rgb(0, 107, 255)" : isDarkMode ? "rgba(255,255,255,.8)" : "rgba(0, 0, 0,.4)",
                  transition: "all 0.3s ease",
                }}
              >
                <div className="flex justify-center">{tab.icon}</div>
                <h1 style={{ fontSize: "1rem", fontFamily: "Gilroy, sans-serif" }}>
                  <b>{tab.label}</b>
                </h1>
              </Tab>
            ))}
          </TabList>
  
          <TabPanels>
          <TabPanel>
  <div className="jack1 w-full  dark:text-white">
    <div className="ryu">
      <Heading className="text-lg font-semibold">Développement Web & Mobile</Heading>
      <Text className="text-xs">
        Nous proposons des services complets de développement web et mobile, allant de la création de sites vitrine à la conception d'applications mobiles performantes. Nos solutions sont adaptées à vos besoins spécifiques et orientées vers l'expérience utilisateur.
      </Text>

      <div className="mt-3">
        <div className="group dark:bg-gray-800 dark:text-white bg-gray-50 relative flex items-center gap-x-6 rounded-lg py-2 px-5 mb-2 border-b hover:bg-gray-100">
        <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-gray-200 p-3">
        <FaLaptop className="text-gray-600 group-hover:text-sky-500" size={20} />
          </div>
          <div className="flex-auto">
            <div className="text-sm font-semibold text-gray-800 dark:text-white">Création de sites web</div>
            <div className="text-xs dark:text-gray-300 text-gray-500">
              Conception de sites web optimisés et responsive, adaptés à tous les appareils. Que ce soit pour un site vitrine ou un site e-commerce, nous mettons l'accent sur la performance et l'expérience utilisateur.
            </div>
          </div>
        </div>

        <div className="group bg-gray-50 dark:bg-gray-800 dark:text-white  relative flex items-center gap-x-6 rounded-lg py-2 px-5 mb-2 border-b hover:bg-gray-100">
        <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-gray-200 p-3">
            <FaSitemap className="text-gray-600 group-hover:text-sky-500" size={20} />
          </div>
          <div className="flex-auto">
            <div className="text-sm font-semibold text-gray-800 dark:text-white">Développement de solutions web sur mesure</div>
            <div className="text-xs text-gray-500 dark:text-gray-300">
              Création d'applications web adaptées à vos besoins, incluant des plateformes de gestion, des outils collaboratifs, et des solutions de vente en ligne.
            </div>
          </div>
        </div>

        <div className="group dark:bg-gray-800  bg-gray-50 relative flex items-center gap-x-6 rounded-lg py-2 px-5 mb-2 border-b hover:bg-gray-100">
        <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-gray-200 p-3">
            <FaAppStoreIos className="text-gray-600 group-hover:text-sky-500" size={20} />
          </div>
          <div className="flex-auto">
            <div className="text-sm dark:text-white font-semibold text-gray-800">Développement de logiciels personnalisés</div>
            <div className="text-xs text-gray-500 dark:text-gray-300">
              Conception de logiciels internes pour optimiser la productivité de vos équipes, incluant des solutions pour la gestion de projets et le suivi des clients.
            </div>
          </div>
        </div>

        <div className="group dark:bg-gray-800 bg-gray-50 relative flex items-center gap-x-6 rounded-lg py-2 px-5 mb-2 border-b hover:bg-gray-100">
        <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-gray-200 p-3">
            <FaMobileAlt className="text-gray-600 group-hover:text-sky-500" size={20} />
          </div>
          <div className="flex-auto">
            <div className="text-sm dark:text-white font-semibold text-gray-800">Développement d'applications mobiles</div>
            <div className="text-xs dark:text-gray-300 text-gray-500">
              Applications natives pour iOS et Android avec React Native, offrant des interfaces modernes et intuitives pour une excellente expérience utilisateur.
            </div>
          </div>
        </div>

        <div className="group bg-gray-50 relative dark:bg-gray-800 flex items-center gap-x-6 rounded-lg py-2 px-5 mb-2 border-b hover:bg-gray-100">
        <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-gray-200 p-3">
            <FaToolbox className="text-gray-600 group-hover:text-sky-500" size={20} />
          </div>
          <div className="flex-auto">
            <div className="text-sm font-semibold text-gray-800 dark:text-white">Support et maintenance de votre solution</div>
            <div className="text-xs text-gray-500 dark:text-gray-300">
              Maintenance proactive pour garantir le bon fonctionnement et la sécurité de vos sites et applications. Nous vous accompagnons avec des mises à jour régulières et un suivi technique continu.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="g2 mx-auto text-center block">
      <dotlottie-player
        src="https://lottie.host/2874325c-612c-4fb7-a9b1-618bc2cda35d/s4RKHdAYJJ.json"
        background="transparent"
        speed="1"
        style={{ width: 'auto', height: '500px' }}
        loop
        autoplay
      ></dotlottie-player>
    </div>
  </div>
</TabPanel>

           <TabPanel>
  <div className="jack1 w-full">
    <div className="ryu">
      <Heading className="text-lg font-semibold">Applications Mobiles</Heading>
      <Text className="text-xs mt-2 mb-4">
        Développement d'applications mobiles performantes et intuitives pour iOS et Android avec React Native. Nous créons des solutions modernes et optimisées pour offrir une expérience utilisateur exceptionnelle.
      </Text>

      {[
        {
          text: "Applications natives pour iOS et Android, conçues pour une performance maximale sur chaque plateforme.",
          icon: <FaMobileAlt className="h-6 w-6 text-gray-600 group-hover:text-blue-500" size={20} />,
        },
        {
          text: "Interfaces utilisateurs modernes et intuitives, adaptées aux standards actuels et optimisées pour une utilisation fluide.",
          icon: <FaAppStoreIos className="h-6 w-6 text-gray-600 group-hover:text-blue-500" size={20} />,
        },
        {
          text: "Intégration de fonctionnalités avancées : géolocalisation, notifications push, paiement en ligne et bien plus.",
          icon: <FaSitemap className="h-6 w-6 text-gray-600 group-hover:text-blue-500" size={20} />,
        },
        {
          text: "Optimisation des performances pour garantir une réactivité optimale et une faible consommation d'énergie.",
          icon: <FaToolbox className="h-6 w-6 text-gray-600 group-hover:text-blue-500" size={20} />,
        },
        {
          text: "Maintenance et mises à jour régulières pour assurer la sécurité et la compatibilité avec les nouvelles versions des systèmes d'exploitation.",
          icon: <FaTools className="h-6 w-6 text-gray-600 group-hover:text-blue-500" size={20} />,
        },
      ].map((service, index) => (
        <div key={index} className="group bg-gray-50 relative flex items-center gap-x-4 rounded-lg py-2 px-4 mb-2 hover:bg-gray-100">
          <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-gray-100">
            {service.icon}
          </div>
          <div className="flex-auto">
            <Text className="text-gray-600 text-xs">{service.text}</Text>
          </div>
        </div>
      ))}
    </div>

    <div className="g2 mx-auto text-center block">
      <dotlottie-player
        src="https://lottie.host/f5b16728-f1e8-4aa9-b553-c6d790742954/jdEMTw0EVm.json"
        background="transparent"
        speed="1"
        style={{ width: 'auto', height: '300px' }}
        loop
        autoplay
      ></dotlottie-player>
    </div>
  </div>
</TabPanel>

            <TabPanel>
  <div className="jack1">
    <div className="ryu">
      <Heading className="text-lg font-semibold">Conception Graphique</Heading>
      <Text className="text-xs mt-2 mb-4">
        Création d'identités visuelles uniques pour mettre en valeur votre marque et captiver votre audience.
      </Text>

      {[
        {
          text: "Création de logos sur mesure pour refléter l'essence de votre marque.",
          icon: <FaPaintBrush className="h-6 w-6 text-gray-600 group-hover:text-purple-500" size={20} />,
        },
        {
          text: "Design de cartes de visite professionnelles et impactantes pour laisser une première impression mémorable.",
          icon: <FaLaptop className="h-6 w-6 text-gray-600 group-hover:text-purple-500" size={20} />,
        },
        {
          text: "Conception de supports marketing : brochures, flyers, affiches, etc., adaptés à votre audience.",
          icon: <SiAdobecreativecloud className="h-6 w-6 text-gray-600 group-hover:text-purple-500" size={20} />
        },
        {
          text: "Création d'identités visuelles complètes : choix de couleurs, typographies et charte graphique.",
          icon: <FaSitemap className="h-6 w-6 text-gray-600 group-hover:text-purple-500" size={20} />,
        },
        {
          text: "Illustrations personnalisées et infographies pour renforcer la communication visuelle.",
          icon: <FaToolbox className="h-6 w-6 text-gray-600 group-hover:text-purple-500" size={20} />,
        },
      ].map((service, index) => (
        <div key={index} className="group bg-gray-50 relative flex items-center gap-x-4 rounded-lg py-2 px-4 mb-2 hover:bg-gray-100">
          <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-gray-100">
            {service.icon}
          </div>
          <div className="flex-auto">
            <Text className="text-gray-600 text-xs">{service.text}</Text>
          </div>
        </div>
      ))}
    </div>

    <div className="g2 mx-auto text-center block">
      <dotlottie-player
        src="https://lottie.host/12863dbb-23a2-4ac6-bd81-865958670e52/RNNliTiT9x.json"
        background="transparent"
        speed="1"
        style={{ width: 'auto', height: '300px' }}
        loop
        autoplay
      ></dotlottie-player>
    </div>
  </div>
</TabPanel>

            <TabPanel>
  <div className="jack1">
    <div className="ryu">
      <Heading className="text-lg font-semibold">Dépannage Informatique</Heading>
      <Text className="text-xs mt-2 mb-4">
        Assistance technique complète pour la mise à niveau de matériel et la résolution de problèmes informatiques, destinée aux particuliers et aux entreprises.
      </Text>

      {[
        {
          text: "Diagnostic rapide et précis des problèmes matériels et logiciels.",
          icon: <FaTools className="h-6 w-6 text-gray-600 group-hover:text-blue-500" size={20} />,
        },
        {
          text: "Upgrade de matériel : augmentation de la RAM, installation de SSD, et optimisation de performance.",
          icon: <FaToolbox className="h-6 w-6 text-gray-600 group-hover:text-blue-500" size={20} />,
        },
        {
          text: "Réparation et dépannage d'ordinateurs portables et fixes pour améliorer la productivité.",
          icon: <FaDesktop className="h-6 w-6 text-gray-600 group-hover:text-blue-500" size={20} />,
        },
        {
          text: "Support à distance pour la résolution de problèmes logiciels (installation, paramétrage, mises à jour).",
          icon: <FaAppStoreIos className="h-6 w-6 text-gray-600 group-hover:text-blue-500" size={20} />,
        },
        {
          text: "Sécurisation des données et mise en place de solutions de sauvegarde pour protéger vos informations.",
          icon: <FaShieldAlt className="h-6 w-6 text-gray-600 group-hover:text-blue-500" size={20} />,
        },
      ].map((service, index) => (
        <div key={index} className="group bg-gray-50 relative flex items-center gap-x-4 rounded-lg py-2 px-4 mb-2 hover:bg-gray-100">
          <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-gray-100">
            {service.icon}
          </div>
          <div className="flex-auto">
            <Text className="text-gray-600 text-xs">{service.text}</Text>
          </div>
        </div>
      ))}
    </div>

    <div className="g2 mx-auto text-center block">
      <dotlottie-player
        src="https://lottie.host/eec08202-5846-483d-82ec-24402ff7dc57/gs1IrqZTKn.json"
        background="transparent"
        speed="1"
        style={{ width: 'auto', height: '300px' }}
        loop
        autoplay
      ></dotlottie-player>
    </div>
  </div>
</TabPanel>

            <TabPanel>
              <div className="jack1">
                <div className="ryu">
                  <Heading className="text-lg font-semibold">Suppression de mots de passe</Heading>
                  <Text className="text-xs mb-3">
                    Services de récupération et de suppression de mots de passe pour Android, iOS et Windows, sécurisés et efficaces.
                    <span class="mt-2 inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">Une preuve d'achat à votre nom requise avant toute opération sur le matériel.</span>

                  </Text>
                  {[
        {
          text: "Suppression des mots de passe pour les appareils personnels ou professionnels (sous conditions légales).",
          icon: <FaTools className="h-6 w-6 text-gray-600 group-hover:text-blue-500" size={20} />,
        },
        {
          text: "Assistance pour la récupération de données suite à une perte de mot de passe.",
          icon: <FaDatabase className="h-6 w-6 text-gray-600 group-hover:text-blue-500" size={20} />,
        },
        {
          text: "Respect des normes de sécurité et de confidentialité des données.",
          icon: <FaShieldAlt className="h-6 w-6 text-gray-600 group-hover:text-blue-500" size={20} />,
        },
        {
          text: "Support technique tout au long du processus pour assurer un service transparent.",
          icon: <FaToolbox className="h-6 w-6 text-gray-600 group-hover:text-blue-500" size={20} />,
        },
       
      ].map((service, index) => (
        <div key={index} className="group bg-gray-50 relative flex items-center gap-x-4 rounded-lg py-2 px-4 mb-2 hover:bg-gray-100">
          <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-gray-100">
            {service.icon}
          </div>
          <div className="flex-auto">
            <Text className="text-gray-600 text-xs">{service.text}</Text>
          </div>
        </div>
      ))}
                 {/* <div className="group mt-3 bg-gray-200 relative flex items-center gap-x-6 rounded-lg py-1 px-5 text-sm/6  w-auto mb-2 border-b">
                    <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-gray-100">
                    <FaShieldAlt className="h-6 w-6 text-gray-600 group-hover:text-green-500" size={20} />
                    </div>
                    <div className="flex-auto">
                      <div className="text-gray-500 text-xs">Suppression des mots de passe pour les appareils personnels ou professionnels (sous conditions légales).</div>
                    </div>
                  </div>
                 <div className="group bg-gray-200 relative flex items-center gap-x-6 rounded-lg py-1 px-5 text-sm/6  w-auto mb-2 border-b">
                    <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-gray-100">
                    <FaShieldAlt className="h-6 w-6 text-gray-600 group-hover:text-green-500" size={20} />
                    </div>
                    <div className="flex-auto">
                      <div className="text-gray-500 text-xs"> Assistance pour la récupération de données suite à une perte de mot de passe.</div>
                    </div>
                  </div>
                 <div className="group bg-gray-200 relative flex items-center gap-x-6 rounded-lg py-1 px-5 text-sm/6  w-auto mb-2 border-b">
                    <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-gray-100">
                    <FaShieldAlt className="h-6 w-6 text-gray-600 group-hover:text-green-500" size={20} />
                    </div>
                    <div className="flex-auto">
                      <div className="text-gray-500 text-xs">Respect des normes de sécurité et de confidentialité des données.</div>
                    </div>
                  </div>
                 <div className="group relative bg-gray-200 flex items-center gap-x-6 rounded-lg py-1 px-5 text-sm/6  w-auto mb-2 border-b">
                    <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-gray-100">
                    <FaShieldAlt className="h-6 w-6 text-gray-600 group-hover:text-green-500" size={20} />
                    </div>
                    <div className="flex-auto">
                      <div className="text-gray-500 text-xs">Support technique tout au long du processus pour assurer un service transparent.</div>
                    </div>
                  </div>*/}
                </div> 
                <div className="g2 mx-auto text-center block">
                <dotlottie-player src="https://lottie.host/b9c1a50d-6972-4533-8328-ca8bae7735a5/21BDZ6XbCP.json" background="transparent" speed="1" style={{width: 'auto', height: '250px'}} loop autoplay></dotlottie-player>
                </div>
              </div>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    );
  }
  
  export default Tabpage;
  