import React from 'react'
// import '../compiled.css'
import './Header.css'
import { useLanguage } from '../Components/LanguageContext'; // Importez le hook personnalisé
import { useTranslation } from 'react-i18next';
import '../i18n';
function HeaderBanner({ isDarkMode, showConnectBtn, title, content, showImage, imgSrc, imgWidth }) {
  const { t } = useTranslation();
  return (
    <div className='header-container' style={{ position: 'relative', margin: 'auto', padding: 0 }}>
      <div className={`ab ${isDarkMode ? 'bg-gray-800' : ''}`}>
        <div className="ark ary cfc cfm ddh">
          <div className="gx tv avl">
            <h2 className={`avs awd awx chx ${isDarkMode ? 'avs awd awx bah chx' : ''}`}>{title}</h2>
            <p className="gx lk uk avz awr axo axr mb-5 dark:text-sky-500">{content}</p>
            {showConnectBtn && (
              <>
                {/* <small className='gx lk uk awr axo axr dark:text-white'>{t('Titre des boutons de connexion')}</small>
                <div className="mx-auto justify-center mt-2 items-center flex ps-4">
                  <a href="#" className="flex me-5 items-center adu alo px-9 arv  awg axv bbn bbt bbx bcf hover:bg-gray-50 shadow hover:shadow-md">
                    <img src="./images/Google-logo.png" style={{ height: '18px', width: 'auto', marginRight: '5px' }} alt="" />
                    Google
                  </a>
                  <a href="#" className="flex items-center adu alo px-9 arv  awg axv bbn bbt bbx bcf  hover:bg-gray-50 shadow hover:shadow-md">
                    <img src="./images/Microsoft-logo.png" style={{ height: '15px', width: 'auto', marginRight: '5px' }} alt="" />
                    Microsoft
                  </a>

                </div> */}
              
            {/* <div className="asf w-80 mx-auto">
              <div className="gx tv ari" >
                <div className="ab">
                  <div className="aa ak lx yz" aria-hidden="true">
                    <div className="tn afm afv"></div>
                  </div>
                  <div className="ab lx ze">
                    <span className={isDarkMode ? 'bg-gray-800 ard awa axr font-bold' : 'bg-white px-3 axr font-bold'}>{t('OU')}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="lx justify-center items-center text-white">
              <a href="" className="px-4 py-2 font-semibold text-sm bg-cyan-500 text-white rounded-full shadow-sm hover:bg-cyan-400 hover:shadow-cyan-500/50 hover:shadow-md

transition-all">{t('Inscrivez-vous gratuitement par e-mail')}</a>
              <span className='axr font-bold dark:text-white py-2 px-4'>{t('Pas de carte de crédit nécessaire.')}</span>
            </div> */}
            </>
            )}
          </div>
{showImage && (
   <div className="wow fadeInUp relative z-10 mx-auto max-w-[845px]" data-wow-delay=".25s" style={{position:'relative'}}>
            <div className="mt-16">
            <dotlottie-player src="https://lottie.host/ee6133f8-131c-4a9f-83ca-1e7735283deb/kFrSzOG4bZ.json" background="transparent" className="mx-auto border max-w-full text-center rounded-t-xl rounded-tr-xl" speed="1" style={{'width': 'auto', 'height': '600px', 'margin': 'auto'}} loop autoplay></dotlottie-player>
              {/* <img src={imgSrc? imgSrc : '/images/schedule-c.png'}  alt="" className="mx-auto border max-w-full rounded-t-xl rounded-tr-xl" style={{width: imgWidth}} /> */}
            </div>
            <div className="absolute  -left-0 z-[-1]" style={{ bottom: 150, left:50,zIndex:-1 }}>
              <img src={isDarkMode ? "images/dot-1.svg" : "images/dot-1-black.svg"} alt="point" />
            </div>
            <div >
              <img src={isDarkMode ? "images/dot-2.svg" : "images/dot-1-black.svg"} style={{position:'absolute', top: 100, right:50, zIndex:-1 }} alt="point" />
            </div>
          </div>
)}
         

        </div>

      </div>


      <div className="wave md:hidden" style={{ position: 'absolute', bottom: 35, left: 0, right: 0, height: '20px' }}>
        <svg width="100%" viewBox="0 0 3000 800" className='wave-svg'>
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Apple-TV" transform="translate(0.000000, -330.000000)" fill={isDarkMode ? "#111827" : "#FFFFFF"}>
              <path d="M0,500 C500,450 1000,450 1500,500 C2000,550 2500,550 3000,500 L3000,800 L0,800 Z" id="Path"></path>
            </g>
          </g>
        </svg>
      </div>
      <div className="wave hidden md:block lg:hidden" style={{ position: 'absolute', bottom: 60, left: 0, right: 0, height: '20px' }}>
        <svg width="100%" viewBox="0 0 3000 800" className='wave-svg'>
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Apple-TV" transform="translate(0.000000, -330.000000)" fill={isDarkMode ? "#111827" : "#FFFFFF"}>
              <path d="M0,500 C500,450 1000,450 1500,500 C2000,550 2500,550 3000,500 L3000,800 L0,800 Z" id="Path"></path>
            </g>
          </g>
        </svg>
      </div>
      <div className="wave hidden lg:block " style={{ position: 'absolute', bottom: 100, left: 0, right: 0, height: '20px' }}>
        <svg width="100%" viewBox="0 0 3000 800" className='wave-svg'>
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Apple-TV" transform="translate(0.000000, -330.000000)" fill={isDarkMode ? "#111827" : "#FFFFFF"}>
              <path d="M0,500 C500,450 1000,450 1500,500 C2000,550 2500,550 3000,500 L3000,800 L0,800 Z" id="Path"></path>
            </g>
          </g>
        </svg>
      </div>
      <div className="wave hidden xl:block " style={{ position: 'absolute', bottom: 130, left: 0, right: 0, height: '20px' }}>
        <svg width="100%" viewBox="0 0 3000 800" className='wave-svg'>
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Apple-TV" transform="translate(0.000000, -330.000000)" fill={isDarkMode ? "#111827" : "#FFFFFF"}>
              <path d="M0,500 C500,450 1000,450 1500,500 C2000,550 2500,550 3000,500 L3000,800 L0,800 Z" id="Path"></path>
            </g>
          </g>
        </svg>
      </div>



    </div>

  )
}

export default HeaderBanner