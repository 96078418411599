import React, { useRef } from 'react';
import './ThemeCarousel.css'; // Supposant que vous ayez un fichier ThemeCarousel.css
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";:

const themes = [
  {name: 'Template1', img: '/images/invoice-template/general_11.jpeg'},
  {name: 'Template2', img: '/images/invoice-template/general_3.jpeg'},
  {name: 'Template3', img: '/images/invoice-template/general_4.jpeg'},
  {name: 'Template2', img: '/images/invoice-template/general_3.jpeg'},
  {name: 'Template1', img: '/images/invoice-template/general_11.jpeg'},
  {name: 'Template2', img: '/images/invoice-template/general_3.jpeg'},
  {name: 'Template1', img: '/images/invoice-template/general_11.jpeg'},
  {name: 'Template2', img: '/images/invoice-template/general_3.jpeg'},
  {name: 'Template1', img: '/images/invoice-template/general_11.jpeg'},
  {name: 'Template2', img: '/images/invoice-template/general_3.jpeg'},
  {name: 'Template1', img: '/images/invoice-template/general_11.jpeg'},
  {name: 'Template2', img: '/images/invoice-template/general_3.jpeg'},

  // Ajoutez plus de thèmes ici
];

function ThemeCarousel({ setSelectedTemplate }) {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      arrows: false,

      slidesToShow: 5,
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            arrows: false,

            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            arrows: false,

            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,

            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
  
    return (
        <div className='slider-invoice-theme' style={{ overflowX: 'hidden' }}>
      <Slider {...settings}>
        {themes.map((theme, index) => (
          <div key={index}>
            <img
              src={theme.img}
              alt={theme.name} style={{width: '25vh'}}
              onClick={() => setSelectedTemplate(theme.name)}
            />
          </div>
        ))}
      </Slider>
      </div>
    );
  }

  export default ThemeCarousel;
