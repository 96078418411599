import React, {useRef, useEffect, useState} from 'react'
import './Template1.css'
function Template1({invoiceData, onFieldChange })   {
  const companyNameRef = useRef(null);
  const companyAddressRef = useRef(null);
  const companyZipCodeRef = useRef(null);
  const companyCityRef = useRef(null);
  const companyPhoneRef = useRef(null);
  const clientLastnameRef = useRef(null);
  const clientFirstnameRef = useRef(null);

  const [editableCompanyName, setEditableCompanyName] = useState(invoiceData.companyName ||'GeniusCalendar');
  const [editableCompanyAddress, setEditableCompanyAddress] = useState(invoiceData.companyAddress ||'12 rue du grenouiller');
  const [editableCompanyZipCode, setEditableCompanyZipCode] = useState(invoiceData.companyZipCode ||'71200');
  const [editableCompanyCity, setEditableCompanyCity] = useState(invoiceData.companyCity ||'Le Creusot');
  const [editableCompanyPhone, setEditableCompanyPhone] = useState(invoiceData.companyPhone ||'GeniusCalendar');






  const [editableClientLastname, setEditableClientLastname] = useState(invoiceData.clientLastname ||'Durand');
  const [editableClientFirstname, setEditableClientFirstname] = useState(invoiceData.clientFirstname ||'Jean');

  useEffect(() => {
    if (companyNameRef.current) {
      companyNameRef.current.textContent = editableCompanyName;
    }
    if (companyAddressRef.current) {
      companyAddressRef.current.textContent = editableCompanyAddress;
    }
    if (companyZipCodeRef.current) {
      companyZipCodeRef.current.textContent = editableCompanyZipCode;
    }
    if (companyCityRef.current) {
      companyCityRef.current.textContent = editableCompanyCity;
    }
    if (companyPhoneRef.current) {
      companyPhoneRef.current.textContent = editableCompanyCity;
    }
   



    if (clientLastnameRef.current) {
      clientLastnameRef.current.textContent = editableClientLastname;
    }
    if (clientFirstnameRef.current) {
      clientFirstnameRef.current.textContent = editableClientFirstname;
    }
    // Mettez à jour d'autres champs éditables ici
  }, [editableCompanyName, editableClientLastname, editableClientFirstname]);

  const handleCompanyNameInput = (event) => {
    const newCompanyName = event.target.textContent;
    setEditableCompanyName(newCompanyName);
    onFieldChange('companyName', newCompanyName);
  };
  const handleCompanyAddressInput = (event) => {
    const newCompanyAddress = event.target.textContent;
    setEditableCompanyAddress(newCompanyAddress);
    onFieldChange('companyAddress', newCompanyAddress);
  };
  const handleCompanyZipCodeInput = (event) => {
    const newCompanyZipCode = event.target.textContent;
    setEditableCompanyZipCode(newCompanyZipCode);
    onFieldChange('companyZipCode', newCompanyZipCode);
  };
  const handleCompanyCityInput = (event) => {
    const newCompanyCity = event.target.textContent;
    setEditableCompanyCity(newCompanyCity);
    onFieldChange('companyCity', newCompanyCity);
  };
  const handleCompanyPhoneInput = (event) => {
    const newCompanyPhone = event.target.textContent;
    setEditableCompanyPhone(newCompanyPhone);
    onFieldChange('companyPhone', newCompanyPhone);
  };

  const handleClientLastnameInput = (event) => {
    const newClientLastname = event.target.textContent;
    setEditableClientLastname(newClientLastname);
    onFieldChange('clientLastname', newClientLastname);
  };

  const handleClientFirstnameInput = (event) => {
    const newClientFirstname = event.target.textContent;
    setEditableClientFirstname(newClientFirstname);
    onFieldChange('clientFirstname', newClientFirstname);
  };


  return (
    <div>
        <div class="tm_container">
    <div class="tm_invoice_wrap">
      <div class="tm_invoice tm_style3" id="tm_download_section">
        <div class="tm_invoice_in position-relative">
          <div class="tm_invoice_head tm_align_center tm_accent_bg">
            <div class="tm_invoice_left">
              <div class="tm_logo"><img src="images/calendar-icon.png" className='h-[100px]' alt="Logo"/></div>
            </div>
            <div class="tm_invoice_right">
              <div class="tm_head_address tm_white_color">
              <span
            ref={companyNameRef}
            contentEditable={true}
            onBlur={handleCompanyNameInput}
          >
            {editableCompanyName}
          </span> <br/>
          <span
            ref={companyAddressRef}
            contentEditable={true}
            onBlur={handleCompanyAddressInput}
          >{editableCompanyAddress}
          </span>, <span
            ref={companyZipCodeRef}
            contentEditable={true}
            onBlur={handleCompanyZipCodeInput}
          >{editableCompanyZipCode}
          </span> <span
            ref={companyCityRef}
            contentEditable={true}
            onBlur={handleCompanyCityInput}
          >{editableCompanyCity}
          </span><br/>
                Phone: <span
            ref={companyPhoneRef}
            contentEditable={true}
            onBlur={handleCompanyPhoneInput}
          >{editableCompanyPhone}
          </span> <br/>
                Email: demo@gmail.com
              </div>
            </div>
            <div class="tm_primary_color tm_text_uppercase tm_watermark_title tm_white_color ">GeniusCalendar</div>
          </div>
          <div class="tm_invoice_info">
            <div class="tm_invoice_info_left tm_gray_bg">
              <p class="tm_mb2"><b class="tm_primary_color">Invoice To:</b></p>
              <p class="tm_mb0">
                Lowell H. Dominguez <br/>
                84 Spilman Street, London <br/>
                lowell@gmail.com <br/>
                +990 9879 654 0976
              </p>
            </div>
            <div class="tm_invoice_info_right tm_text_right">
              <p class="tm_invoice_number tm_m0">Invoice No: <b class="tm_primary_color">#LL93784</b></p>
              <p class="tm_invoice_date tm_m0">Date: <b class="tm_primary_color">01.07.2022</b></p>
            </div>
          </div>
          <div class="tm_invoice_details">
            <div class="tm_table tm_style1 tm_mb30">
              <div class="tm_border">
                <div class="tm_table_responsive">
                  <table class="tm_gray_bg">
                    <thead>
                      <tr>
                        <th class="tm_width_5 tm_semi_bold tm_white_color tm_accent_bg">Description</th>
                        <th class="tm_width_1 tm_semi_bold tm_white_color tm_accent_bg tm_border_left">Qty</th>
                        <th class="tm_width_2 tm_semi_bold tm_white_color tm_accent_bg tm_border_left">Price</th>
                        <th class="tm_width_2 tm_semi_bold tm_white_color tm_accent_bg tm_border_left">Discount</th>
                        <th class="tm_width_2 tm_semi_bold tm_white_color tm_accent_bg tm_border_left tm_text_right">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="tm_width_5">Laptop</td>
                        <td class="tm_width_1 tm_border_left">1</td>
                        <td class="tm_width_2 tm_border_left">$1000</td>
                        <td class="tm_width_2 tm_border_left">10%</td>
                        <td class="tm_width_2 tm_border_left tm_text_right">$900</td>
                      </tr>
                      <tr>
                        <td class="tm_width_5">Smartphone</td>
                        <td class="tm_width_1 tm_border_left">1</td>
                        <td class="tm_width_2 tm_border_left">$800</td>
                        <td class="tm_width_2 tm_border_left">20%</td>
                        <td class="tm_width_2 tm_border_left tm_text_right">$640</td>
                      </tr>
                      <tr>
                        <td class="tm_width_5">Headphones</td>
                        <td class="tm_width_1 tm_border_left">1</td>
                        <td class="tm_width_2 tm_border_left">$150</td>
                        <td class="tm_width_2 tm_border_left">0%</td>
                        <td class="tm_width_2 tm_border_left tm_text_right">$150</td>
                      </tr>
                      <tr>
                        <td class="tm_width_5">Backpack</td>
                        <td class="tm_width_1 tm_border_left">1</td>
                        <td class="tm_width_2 tm_border_left">$50</td>
                        <td class="tm_width_2 tm_border_left">0%</td>
                        <td class="tm_width_2 tm_border_left tm_text_right">$50</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="tm_invoice_footer">
                <div class="tm_left_footer">
                  <p class="tm_mb2"><b class="tm_primary_color">Payment info:</b></p>
                  <p class="tm_m0">Credit Card - 236***********928 <br/>Amount: $1732</p>
                </div>
                <div class="tm_right_footer">
                  <table class="tm_gray_bg">
                    <tbody>
                      <tr>
                        <td class="tm_width_3 tm_primary_color tm_bold">Total</td>
                        <td class="tm_width_3 tm_primary_color tm_text_right tm_bold">$1740</td>
                      </tr>
                      <tr>
                        <td class="tm_width_3 tm_primary_color tm_bold">Paid</td>
                        <td class="tm_width_3 tm_primary_color tm_bold tm_text_right">$1000</td>
                      </tr>
                      <tr class="tm_border_top tm_border_bottom tm_accent_bg">
                        <td class="tm_width_3 tm_border_top_0 tm_bold tm_f16 tm_white_color">Due	</td>
                        <td class="tm_width_3 tm_border_top_0 tm_bold tm_f16 tm_white_color tm_text_right">$740</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="tm_padd_15_20 tm_gray_bg">
              <p class="tm_mb5"><b class="tm_primary_color">Terms & Conditions:</b></p>
              <ul class="tm_m0 tm_note_list">
                <li>All claims relating to quantity or shipping errors shall be waived by Buyer unless made in writing to Seller within thirty (30) days after delivery of goods to the address stated.</li>
                <li>Delivery dates are not guaranteed and Seller has no liability for damages that may be incurred due to any delay in shipment of goods hereunder. Taxes are excluded unless otherwise stated.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="tm_invoice_btns tm_hide_print">
        <a href="javascript:window.print()" class="tm_invoice_btn tm_color1">
          <span class="tm_btn_icon">
            <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><path d="M384 368h24a40.12 40.12 0 0040-40V168a40.12 40.12 0 00-40-40H104a40.12 40.12 0 00-40 40v160a40.12 40.12 0 0040 40h24" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"/><rect x="128" y="240" width="256" height="208" rx="24.32" ry="24.32" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"/><path d="M384 128v-24a40.12 40.12 0 00-40-40H168a40.12 40.12 0 00-40 40v24" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"/><circle cx="392" cy="184" r="24" fill='currentColor'/></svg>
          </span>
          <span class="tm_btn_text">Print</span>
        </a>
        <button id="tm_download_btn" class="tm_invoice_btn tm_color2">
          <span class="tm_btn_icon">
            <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><path d="M320 336h76c55 0 100-21.21 100-75.6s-53-73.47-96-75.6C391.11 99.74 329 48 256 48c-69 0-113.44 45.79-128 91.2-60 5.7-112 35.88-112 98.4S70 336 136 336h56M192 400.1l64 63.9 64-63.9M256 224v224.03" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
          </span>
          <span class="tm_btn_text">Download</span>
        </button>
      </div>
    </div>
  </div></div>
  )
}

export default Template1