import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";



const resources = {
  en: {
    translation: {
      "Hello": "Hello",
      /*******************
        NAVBAR
      *******************/
      "Accueil": 'Home',
      'Prix': 'Pricing',
      'Connexion': 'Sign in',
      'GeniusCalendar': 'Genius Dev',
      'Genius Dev is out! See what\'s new': 'Genius Dev is out! See what\'s new',
      'new':'New',

      /**
       * FOOTER 
       */
      "Commencez à simplifier vos réservations.": "Optimize your productivity.",

      /**
       * PAGE HEADERS
       */
      // Page d'accueil
      'Titre de la page d\'accueil': 'Transform your ideas into digital projects with tailored solutions.',
      'Sous-titre de la page d\'accueil': 'We develop, optimize, and secure your digital projects at Genius Dev.',

      // Page Services
      'Fonctionnalités de GeniusDev': 'GeniusDev Features',
      "Un calendrier conçu pour maximiser votre productivité et simplifier votre planification quotidienne. Chaque fonction a été pensée pour vous offrir une expérience utilisateur sans pareil.": "A calendar designed to maximize your productivity and simplify your daily planning. Each function has been designed to offer you an unparalleled user experience.",

      // Page Prix
      "Choisissez le plan parfait adapté à vos besoins": "Choose the perfect plan tailored to your needs",
      "Un calendrier conçu pour maximiser votre productivité et simplifier votre planification quotidienne. Chaque fonction a été pensée pour vous offrir une expérience utilisateur sans pareil.": "A calendar designed to maximize your productivity and simplify your daily planning. Each function has been designed to offer you an unparalleled user experience.",
      
      // PAGE 404
      "Page non trouvée": "Page not found",
      "Oops ! On dirait que vous avez suivi un mauvais lien. Si vous pensez qu'il s'agit d'un problème chez nous, veuillez nous le dire.": "Oops! Looks like you followed a bad link. If you think this is a problem with us, please tell us.",
      "Retour à l'accueil": "Go back home",
      
      // SIGN IN COMPONENT
      "Titre des boutons de connexion": 'Sign up for free with Google or Microsoft',
      'OU': 'OR',
      'Inscrivez-vous gratuitement par e-mail': 'Sign up for free with email',
      'Pas de carte de crédit nécessaire.': 'No credit card needed.',
      
      /**
       * PRICE CARD COMPONENT
       */
      'Mois': 'Monthly',
      'Annuel': 'Yearly',
      'Économisez 20%': 'Save 20%',
      
      /**
         * CARROUSSEL COMPONENT
       */
      'Retrouver vos outils préférés et coordonnez-les pour une': 'Find your preferred tools and coordinate them for a',
      'optimisation maximale': 'maximum optimization',

      /*** New Entries ***/
      'Computer Troubleshooting': 'Computer Troubleshooting',
      'Complete technical assistance for hardware upgrades and problem resolution, available for both individuals and businesses.': 'Complete technical assistance for hardware upgrades and problem resolution, available for both individuals and businesses.',
      'Rapid and accurate diagnosis of hardware and software issues.': 'Rapid and accurate diagnosis of hardware and software issues.',
      'Hardware upgrades including RAM installation, SSD upgrades, and performance optimization.': 'Hardware upgrades including RAM installation, SSD upgrades, and performance optimization.',
      'Repair and troubleshooting of both laptops and desktops to improve productivity.': 'Repair and troubleshooting of both laptops and desktops to improve productivity.',
      'Remote support for software problem resolution including installation, configuration, and updates.': 'Remote support for software problem resolution including installation, configuration, and updates.',
      'Data security and implementation of backup solutions to protect vital information.': 'Data security and implementation of backup solutions to protect vital information.',
      'Password Removal Services': 'Password Removal Services',
      'Recovery and removal services for passwords on Android, iOS, and Windows devices, ensuring security and efficiency. A proof of purchase is required before any operation on the device.': 'Recovery and removal services for passwords on Android, iOS, and Windows devices, ensuring security and efficiency. A proof of purchase is required before any operation on the device',
      'Password removal for personal or professional devices (under legal conditions).': 'Password removal for personal or professional devices (under legal conditions).',
      'Assistance with data recovery following a password loss.': 'Assistance with data recovery following a password loss.',
      'Adherence to security and confidentiality standards for data protection.': 'Adherence to security and confidentiality standards for data protection.',
      'Ongoing technical support throughout the process to ensure a transparent service.': 'Ongoing technical support throughout the process to ensure a transparent service.',      'IT Solutions' : 'IT Solutions',
      'Enhance your productivity with our IT solutions.':'Enhance your productivity with our IT solutions.',
      'Discover the latest updates from Genius Dev':'Discover the latest updates from Genius Dev',
      'Cybersecurity and Audits': 'Cybersecurity and Audits',
      'Protect your digital assets with our comprehensive cybersecurity services including audits, malware protection, and firewall setups.': 'Protect your digital assets with our comprehensive cybersecurity services including audits, malware protection, and firewall setups.',
      'Comprehensive cybersecurity audits to identify potential vulnerabilities.': 'Comprehensive cybersecurity audits to identify potential vulnerabilities.',
      'Advanced malware protection systems and firewall configurations.': 'Advanced malware protection systems and firewall configurations.',
      'Ongoing monitoring and security updates to ensure your systems remain secure.': 'Ongoing monitoring and security updates to ensure your systems remain secure.',

      'System Optimization and Maintenance': 'System Optimization and Maintenance',
      'Maximize your system’s efficiency with our optimization and regular maintenance services.': 
      'Maximize your system’s efficiency with our optimization and regular maintenance services.',
      'Performance optimization to enhance system speed and responsiveness.': 
      'Performance optimization to enhance system speed and responsiveness.',
      'Regular maintenance plans to prevent system failures and ensure longevity.': 
      'Regular maintenance plans to prevent system failures and ensure longevity.',


      'Data Recovery and Backup Services': 'Data Recovery and Backup Services',
      'Ensure the safety of your data with our reliable recovery and backup services.': 'Ensure the safety of your data with our reliable recovery and backup services.',
      'Data recovery services for system failures or accidental deletion.': 'Data recovery services for system failures or accidental deletion.',
      'Secure data backup solutions to facilitate easy data recovery.': 'Secure data backup solutions to facilitate easy data recovery.',


      'System Optimization Services': 'System Optimization Services',
      'Expert system analysis and performance enhancements tailored to streamline your IT infrastructure.': 'Expert system analysis and performance enhancements tailored to streamline your IT infrastructure.',
      'Comprehensive system audits to identify and resolve inefficiencies.': 'Comprehensive system audits to identify and resolve inefficiencies.',
      'Custom hardware and software solutions to enhance system performance.': 'Custom hardware and software solutions to enhance system performance.',
      'Workstation customization to meet the unique needs of each user.': 'Workstation customization to meet the unique needs of each user.',
    },
  },
  fr: {
    translation: {
      'new':'New',
      "Hello": "Bonjour",
      /*******************
       NAVBAR
       *******************/
      "Accueil": 'Accueil',
      'Prix': 'Prix',
      'Connexion': 'Connexion',
      'GeniusCalendar': 'Genius Dev',
      'Genius Dev est sorti ! Découvrez les nouveautés': 'Genius Dev est sorti ! Découvrez les nouveautés',
      /**
       * FOOTER 
       */
      "Commencez à simplifier vos réservations.": "Optimiser votre productivité.",

      /**
       * PAGE HEADERS
       */
      // Page d'accueil
      'Titre de la page d\'accueil': 'Transformez vos idées en projets numériques avec des solutions sur mesure.',
      'Sous-titre de la page d\'accueil': 'Nous développons, optimisons et sécurisons vos projets numériques chez Genius Dev.',

      // Page Services
      'Fonctionnalités de GeniusDev': 'Fonctionnalités de Genius Dev',
      "Un calendrier conçu pour maximiser votre productivité et simplifier votre planification quotidienne. Chaque fonction a été pensée pour vous offrir une expérience utilisateur sans pareil.": "Un calendrier conçu pour maximiser votre productivité et simplifier votre planification quotidienne. Chaque fonction a été pensée pour vous offrir une expérience utilisateur sans pareil.",

      // Page Prix
      "Choisissez le plan parfait adapté à vos besoins": "Choisissez le plan parfait adapté à vos besoins",
      "Un calendrier conçu pour maximiser votre productivité et simplifier votre planification quotidienne. Chaque fonction a été pensée pour vous offrir une expérience utilisateur sans pareil.": "Un calendrier conçu pour maximiser votre productivité et simplifier votre planification quotidienne. Chaque fonction a été pensée pour vous offrir une expérience utilisateur sans pareil.",
      
      // PAGE 404
      "Page non trouvée": "Page non trouvée",
      "Oops ! On dirait que vous avez suivi un mauvais lien. Si vous pensez qu'il s'agit d'un problème chez nous, veuillez nous en informer.": "Oops ! On dirait que vous avez suivi un mauvais lien. Si vous pensez qu'il s'agit d'un problème chez nous, veuillez nous en informer.",
      "Retour à l'accueil": "Retour à l'accueil",
      
      // SIGN IN COMPONENT
      "Titre des boutons de connexion": 'Inscrivez-vous gratuitement avec Google ou Microsoft',
      'OU': 'OU',
      'Inscrivez-vous gratuitement par e-mail': 'Inscrivez-vous gratuitement par e-mail',
      'Pas de carte de crédit nécessaire.': 'Pas de carte de crédit nécessaire.',

      /**
       * PRICE CARD COMPONENT
       */
      'Mois': 'Mois',
      'Annuel': 'Annuel',
      'Économisez 20%': 'Économisez 20%',

      /**
         * CARROUSSEL COMPONENT
       */
      'Retrouver vos outils préférés et coordonnez-les pour une': 'Retrouver vos outils préférés et coordonnez-les pour une',
      'optimisation maximale': 'optimisation maximale',

      /*** New Entries ***/
      'Computer Troubleshooting': 'Dépannage Informatique',
      'Complete technical assistance for hardware upgrades and problem resolution, available for both individuals and businesses.': 'Assistance technique complète pour la mise à niveau de matériel et la résolution de problèmes, disponible tant pour les particuliers que pour les entreprises.',
      'Rapid and accurate diagnosis of hardware and software issues.': 'Diagnostic rapide et précis des problèmes matériels et logiciels.',
      'Hardware upgrades including RAM installation, SSD upgrades, and performance optimization.': 'Mise à niveau du matériel, y compris l\'installation de RAM, la mise à niveau de SSD et l\'optimisation des performances.',
      'Repair and troubleshooting of both laptops and desktops to improve productivity.': 'Réparation et dépannage d\'ordinateurs portables et de bureau pour améliorer la productivité.',
      'Remote support for software problem resolution including installation, configuration, and updates.': 'Support à distance pour la résolution de problèmes logiciels incluant l\'installation, la configuration et les mises à jour.',
      'Data security and implementation of backup solutions to protect vital information.': 'Sécurisation des données et mise en place de solutions de sauvegarde pour protéger les informations vitales.',
      'Password Removal Services': 'Services de suppression de mots de passe',
      'Recovery and removal services for passwords on Android, iOS, and Windows devices, ensuring security and efficiency. A proof of purchase is required before any operation on the device.': 'Services de récupération et de suppression de mots de passe pour les appareils Android, iOS et Windows, garantissant sécurité et efficacité. Une preuve d\'achat est requise avant toute opération sur l\'appareil.',
      'Password removal for personal or professional devices (under legal conditions).': 'Suppression de mots de passe pour les appareils personnels ou professionnels (sous conditions légales).',
      'Assistance with data recovery following a password loss.': 'Assistance pour la récupération de données suite à une perte de mot de passe.',
      'Adherence to security and confidentiality standards for data protection.': 'Respect des normes de sécurité et de confidentialité pour la protection des données.',
      'Ongoing technical support throughout the process to ensure a transparent service.': 'Support technique tout au long du processus pour assurer un service transparent.',
      'IT Solutions' : 'Solutions informatiques',
      'Enhance your productivity with our IT solutions.':'Améliorez votre productivité avec nos solutions informatiques.',
      'Discover the latest updates from Genius Dev':'Découvrez les dernières mises à jour de Genius Dev',
      'Cybersecurity and Audits': 'Cybersécurité et Audits',
      'Protect your digital assets with our comprehensive cybersecurity services including audits, malware protection, and firewall setups.': 'Protégez vos actifs numériques avec nos services de cybersécurité, notamment des audits, une protection contre les logiciels malveillants et des configurations de pare-feu.',
      'Comprehensive cybersecurity audits to identify potential vulnerabilities.': 'Audits de cybersécurité complets pour identifier les vulnérabilités potentielles.',
      'Advanced malware protection systems and firewall configurations.': 'Systèmes avancés de protection contre les logiciels malveillants et configurations de pare-feu.',
      'Ongoing monitoring and security updates to ensure your systems remain secure.':'Surveillance continue et mises à jour de sécurité pour garantir la sécurité de vos systèmes.',

      'System Optimization and Maintenance': 'Optimisation et maintenance du système',
      'Maximize your system’s efficiency with our optimization and regular maintenance services.': 
      'Maximisez l’efficacité de votre système grâce à nos services d’optimisation et d’entretien régulier.',
      'Performance optimization to enhance system speed and responsiveness.': 
      'Optimisation des performances pour améliorer la vitesse et la réactivité du système.',
      'Regular maintenance plans to prevent system failures and ensure longevity.': 
      'Plans de maintenance réguliers pour prévenir les pannes du système et assurer sa longévité.',

      'Data Recovery and Backup Services': 'Services de récupération et de sauvegarde de données',
      'Ensure the safety of your data with our reliable recovery and backup services.': 'Assurez la sécurité de vos données avec nos services de récupération et de sauvegarde fiables.',
      'Data recovery services for system failures or accidental deletion.': 'Services de récupération de données en cas de panne du système ou de suppression accidentelle.',
      'Secure data backup solutions to facilitate easy data recovery.': 'Solutions de sauvegarde de données sécurisées pour faciliter la récupération des données.',
      
      'System Optimization Services': 'Services d\'optimisation du système',
      'Expert system analysis and performance enhancements tailored to streamline your IT infrastructure.': 'Analyse des systèmes experts et améliorations des performances adaptées pour rationaliser votre infrastructure informatique.',
      'Comprehensive system audits to identify and resolve inefficiencies.': 'Audits complets du système pour identifier et résoudre les inefficacités.',
      'Custom hardware and software solutions to enhance system performance.': 'Solutions matérielles et logicielles personnalisées pour améliorer les performances du système.',
      'Workstation customization to meet the unique needs of each user.': 'Personnalisation du poste de travail pour répondre aux besoins uniques de chaque utilisateur.',
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "fr",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
