import React from 'react';

function InvoiceForm({ invoiceData, setInvoiceData,onFieldChange }) {
  return (
    <div className="inputs">
      <input
        type="text"
        placeholder="Nom de l'entreprise"
        value={invoiceData.companyName || ''}
        onChange={(e) => onFieldChange('companyName', e.target.value)}
      />
      <input
        type="text"
        placeholder="Adresse de l'entreprise"
        value={invoiceData.companyAddress || ''}
        onChange={(e) => onFieldChange('companyAddress', e.target.value)}
      />
      <input
        type="text"
        placeholder="Ville de l'entreprise"
        value={invoiceData.companyCity || ''}
        onChange={(e) => onFieldChange('companyCity', e.target.value)}
      />
   
   <input
  type="text"
  placeholder="Code Postal de l'entreprise"
  value={invoiceData.companyZipCode || ''}
  onChange={(e) => {
    console.log('Input onChange called');
    setInvoiceData({
      ...invoiceData,
      companyZipCode: e.target.value,
    });
    onFieldChange('companyZipCode', e.target.value);
  }}
/>





      <input
        type="text"
        placeholder="Nom du client"
        value={invoiceData.clientLastname || ''}
        onChange={(e) => onFieldChange('clientLastname', e.target.value)}
      />
      <input
        type="text"
        placeholder="Prénom du client"
        value={invoiceData.clientFirstname || ''}
        onChange={(e) => onFieldChange('clientFirstname', e.target.value)}
      />
      <input
        type="date"
        value={(invoiceData.date && invoiceData.date.toISOString().substr(0, 10)) || ''}
        onChange={(e) => onFieldChange('date', new Date(e.target.value))}
      />
      {/* Plus de champs de saisie ici */}
    </div>
  );
}

export default InvoiceForm;
