import React from 'react'
import '../compiled.css'
import { useTranslation } from 'react-i18next';
import { SiFsecure } from "react-icons/si";
import PricingDetailsInformatiquePC from './PricingDetailsInformatiquePC';
import { GrOptimize } from "react-icons/gr";
import { MdBackup } from "react-icons/md";
import { GiLaptop } from "react-icons/gi";

function PricingDetails({ isDarkMode }) {
    const { t } = useTranslation();

    return (
        <div class="  dff">
            <div class="  max-w-screen-2xl gx ark ary cfm ddh ">

                <section aria-labelledby="mobile-comparison-heading" class="">
                    <h2 id="mobile-comparison-heading" class="t dark:text-white">Feature comparison</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 ">
                    <div className="bg-transparent dark:bg-gray-700 rounded-lg shadow-md p-3">
                    <div class="agy jf  afo auo clx">
                                <h3 class="axv awa awg awp dark:text-white flex items-center"><SiFsecure className='me-2 fa-2x text-green-500' />
                                    {t('Cybersecurity and Audits')}</h3>
                                <p class="ku awa awp axs dark:text-white">{t('Protégez vos actifs numériques avec nos services de cybersécurité complets, incluant des audits, une protection contre les malwares et la mise en place de pare-feux.')}</p>
                            </div>
                            <div class="kw abp">
                                <div>
                                    <h4 class="awa awg awp axv dark:text-white">Conçu pour les particuliers</h4>
                                    <div class="ab lk">
                                        <div aria-hidden="true" class="aa as de md rg adt dark:bg-gray-800  bbn bxo"></div>
                                        <div class="bbt bco ab adt dark:bg-gray-800 sm:dark:bg-transparent   bbn cdl cei ciq cis">
                                            <dl class="acc acg awa awp">
                                                <div class="lx yz zf ari arz bxu cat ces">
                                                    <dt class="aue axs dark:text-white">Audit de sécurité approfondi</dt>
                                                    <dd class="lx yz zd cbn cfb">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb text-green-500">
                                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
                                                        </svg>
                                                        <span class="t">Oui</span>
                                                    </dd>
                                                </div>
                                                <div class="lx yz zf ari arz bxu cat ces">
                                                    <dt class="aue axs dark:text-white">Protection contre les malwares</dt>
                                                    <dd class="lx yz zd cbn cfb">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb text-green-500">
                                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
                                                        </svg>
                                                        <span class="t">Oui</span>
                                                    </dd>
                                                </div>
                                                <div class="lx yz zf ari arz bxu cat ces">
                                                    <dt class="aue axs dark:text-white">Configuration de pare-feux</dt>
                                                    <dd class="lx yz zd cbn cfb"><span class="axv dark:text-white">Incluse</span></dd>
                                                </div>
                                                <div class="lx yz zf ari arz bxu cat ces">
                                                    <dt class="aue axs dark:text-white">Surveillance du réseau</dt>
                                                    <dd class="lx yz zd cbn cfb"><span class="axv dark:text-white">24/7</span></dd>
                                                </div>
                                                <div class="lx yz zf ari arz bxu cat ces">
                                                    <dt class="aue axs dark:text-white">Tests d'intrusion basiques</dt>
                                                    <dd class="lx yz zd cbn cfb">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb text-green-500">
                                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
                                                        </svg>
                                                        <span class="t">Oui</span>
                                                    </dd>
                                                </div>
                                            </dl>
                                        </div>
                                        <div aria-hidden="true" class="bbt bco u aa as de md rg adt bxo"></div>
                                    </div>
                                </div>
                                <div>
                                    <h4 class="awa awg awp axv dark:text-white">Autres avantages</h4>
                                    <div class="ab lk">
                                        <div aria-hidden="true" class="aa as de md rg adt dark:bg-gray-800  bbn bxo"></div>
                                        <div class="bbt bco ab adt dark:bg-gray-800 sm:dark:bg-transparent   bbn cdl cei ciq cis">
                                            <dl class="acc acg awa awp">
                                                <div class="lx yz zf ari arz bxu cat ces">
                                                    <dt class="aue axs dark:text-white">Support client 24/7</dt>
                                                    <dd class="lx yz zd cbn cfb">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb text-green-500">
                                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
                                                        </svg>
                                                        <span class="t">Oui</span>
                                                    </dd>
                                                </div>
                                                <div class="lx yz zf ari arz bxu cat ces">
                                                    <dt class="aue axs dark:text-white">Notifications instantanées</dt>
                                                    <dd class="lx yz zd cbn cfb">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb text-green-500">
                                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
                                                        </svg>
                                                        <span class="t">Oui</span>
                                                    </dd>
                                                </div>
                                                <div class="lx yz zf ari arz bxu cat ces">
                                                    <dt class="aue axs dark:text-white">Accès mobile et web</dt>
                                                    <dd class="lx yz zd cbn cfb">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb text-green-500">
                                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
                                                        </svg>
                                                        <span class="t">Oui</span>
                                                    </dd>
                                                </div>
                                            </dl>
                                        </div>
                                        <div aria-hidden="true" class="bbt bco u aa as de md rg adt bxo"></div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="bg-transparent dark:bg-gray-700 rounded-lg shadow-md p-3">
                        <div class="agy jf  afo auo clx">
                                <h3 class=" awa awg awp dark:text-white flex items-center me-1"><GrOptimize style={{ color: '#fff'}} className='me-2 fa-2x dark:text-white' />
                                 Optimisation et Maintenance</h3>
                                <p class="ku awa awp axs dark:text-white">Services de réparation, mise à niveau matérielle et récupération de données pour vos appareils informatiques.</p>
                            </div>
                            <div class="kw abp">
                                <div>
                                    <h4 class="awa awg awp axv dark:text-white">Service d'Optimisation et de Maintenance</h4>
                                    <div class="ab lk">
                                        <div aria-hidden="true" class="aa as de md rg adt dark:bg-gray-800  bbn bxo"></div>
                                        <div class="bbt bco ab adt dark:bg-gray-800 sm:dark:bg-transparent   bbn cdl cei ciq cis">
                                            <dl class="acc acg awa awp">
                                                <div class="lx yz zf ari arz bxu cat ces">
                                                    <dt class="aue axs dark:text-white">Optimisation des performances</dt>
                                                    <dd class="lx yz zd cbn cfb">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
                                                        </svg>
                                                        <span class="t">Oui</span>
                                                    </dd>
                                                </div>
                                                <div class="lx yz zf ari arz bxu cat ces">
                                                    <dt class="aue axs dark:text-white">Mises à jour régulières</dt>
                                                    <dd class="lx yz zd cbn cfb">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
                                                        </svg>
                                                        <span class="t">Oui</span>
                                                    </dd>
                                                </div>
                                                <div class="lx yz zf ari arz bxu cat ces">
                                                    <dt class="aue axs dark:text-white">Maintenance préventive</dt>
                                                    <dd class="lx yz zd cbn cfb"><span class="axv dark:text-white">Incluse</span></dd>
                                                </div>
                                                <div class="lx yz zf ari arz bxu cat ces">
                                                    <dt class="aue axs dark:text-white">Support technique</dt>
                                                    <dd class="lx yz zd cbn cfb"><span class="axv dark:text-white">24/7</span></dd>
                                                </div>
                                                <div class="lx yz zf ari arz bxu cat ces">
                                                    <dt class="aue axs dark:text-white">Sauvegardes régulières</dt>
                                                    <dd class="lx yz zd cbn cfb">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">

                                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
                                                        </svg>
                                                        <span class="t">Oui</span>
                                                    </dd>
                                                </div>
                                            </dl>
                                        </div>
                                        <div aria-hidden="true" class="bbt bco u aa as de md rg adt bxo"></div>
                                    </div>
                                </div>
                                <div>
                                    <h4 class="awa awg awp axv dark:text-white">Autres avantages</h4>
                                    <div class="ab lk">
                                        <div aria-hidden="true" class="aa as de md rg adt dark:bg-gray-800  bbn bxo"></div>
                                        <div class="bbt bco ab adt dark:bg-gray-800 sm:dark:bg-transparent   bbn cdl cei ciq cis">
                                            <dl class="acc acg awa awp">
                                                <div class="lx yz zf ari arz bxu cat ces">
                                                    <dt class="aue axs dark:text-white">Consultation gratuite</dt>
                                                    <dd class="lx yz zd cbn cfb">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
                                                        </svg>
                                                        <span class="t">Oui</span>
                                                    </dd>
                                                </div>
                                                <div class="lx yz zf ari arz bxu cat ces">
                                                    <dt class="aue axs dark:text-white">Notifications de mise à jour</dt>
                                                    <dd class="lx yz zd cbn cfb">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
                                                        </svg>
                                                        <span class="t">Oui</span>
                                                    </dd>
                                                </div>
                                                <div class="lx yz zf ari arz bxu cat ces">
                                                    <dt class="aue axs dark:text-white">Documentation complète</dt>
                                                    <dd class="lx yz zd cbn cfb">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb ayh">
                                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
                                                        </svg>
                                                        <span class="t">Oui</span>
                                                    </dd>
                                                </div>
                                            </dl>
                                        </div>
                                        <div aria-hidden="true" class="bbt bco u aa as de md rg adt bxo"></div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="bg-transparent dark:bg-gray-700 rounded-lg shadow-md p-3">
                        <div class="border-blue-600 jf  afo auo clx">
                                <h3 class="text-blue-600 awa awg awp flex items-center"><GiLaptop className='me-2 fa-2x' />
                                Dépannage Informatique</h3>
                                <p class="ku awa awp axs dark:text-white">Services professionnels pour la réparation et la maintenance de vos appareils.</p>
                            </div>
                            <div class="kw abp">
                                <div>
                                    <h4 class="awa awg awp axv dark:text-white">Services de Dépannage</h4>
                                    <div class="ab lk">
                                        <div aria-hidden="true" class="aa as de md rg adt dark:bg-gray-800 bbn bxo"></div>
                                        <div class="border-blue-600 sm:border-0 border-2 bcy ab adt  dark:bg-gray-800 sm:dark:bg-transparent bbn cdl cei ciq cis">
                                            <dl class="acc acg awa awp">
                                                <div class="lx yz zf ari arz bxu cat ces">
                                                    <dt class="aue axs dark:text-white">Mise à niveau matériel</dt>
                                                    <dd class="lx yz zd cbn cfb">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb text-sky-500">
                                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
                                                        </svg>
                                                        <span class="t">Incluse</span>
                                                    </dd>
                                                </div>
                                                <div class="lx yz zf ari arz bxu cat ces">
                                                    <dt class="aue axs dark:text-white">Diagnostic matériel</dt>
                                                    <dd class="lx yz zd cbn cfb">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb text-sky-500">
                                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
                                                        </svg>
                                                        <span class="t">Incluse</span>
                                                    </dd>
                                                </div>
                                                <div class="lx yz zf ari arz bxu cat ces">
                                                    <dt class="aue axs dark:text-white">Récupération de données</dt>
                                                    <dd class="lx yz zd cbn cfb">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb text-sky-500">
                                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
                                                        </svg>
                                                        <span class="t">Incluse</span>
                                                    </dd>
                                                </div>
                                                <div class="lx yz zf ari arz bxu cat ces">
                                                    <dt class="aue axs dark:text-white">Maintenance et optimisation</dt>
                                                    <dd class="lx yz zd cbn cfb">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb text-sky-500">
                                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
                                                        </svg>
                                                        <span class="t">Incluse</span>
                                                    </dd>
                                                </div>
                                                <div class="lx yz zf ari arz bxu cat ces">
                                                    <dt class="aue axs dark:text-white">Suppression de mot de passe</dt>
                                                    <dd class="lx yz zd cbn cfb">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb text-sky-500">
                                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
                                                        </svg>
                                                        <span class="t dark:text-white">Avec preuve d'achat</span>
                                                    </dd>
                                                </div>
                                            </dl>
                                        </div>
                                        <div aria-hidden="true" class="border-blue-600 border-2 rounded bcy u aa as de md rg adt bxo"></div>
                                    </div>
                                </div>
                                <div>
                                    <h4 class="awa awg awp axv dark:text-white">Autres avantages</h4>
                                    <div class="ab lk">
                                        <div aria-hidden="true" class="aa as de md rg adt dark:bg-gray-800  bbn bxo"></div>
                                        <div class="border-blue-600 border-2 sm:border-0 bcy ab adt dark:bg-gray-800 sm:dark:bg-transparent  bbn cdl cei ciq cis">
                                            <dl class="acc acg awa awp">
                                                <div class="lx yz zf ari arz bxu cat ces">
                                                    <dt class="aue axs dark:text-white">Support client 24/7</dt>
                                                    <dd class="lx yz zd cbn cfb">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb text-sky-500">
                                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
                                                        </svg>
                                                        <span class="t">Oui</span>
                                                    </dd>
                                                </div>
                                                <div class="lx yz zf ari arz bxu cat ces">
                                                    <dt class="aue axs dark:text-white">Interventions rapides</dt>
                                                    <dd class="lx yz zd cbn cfb">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb text-sky-500">
                                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
                                                        </svg>
                                                        <span class="t">Oui</span>
                                                    </dd>
                                                </div>
                                                <div class="lx yz zf ari arz bxu cat ces">
                                                    <dt class="aue axs dark:text-white">Diagnostic à distance</dt>
                                                    <dd class="lx yz zd cbn cfb">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb text-sky-500">
                                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
                                                        </svg>
                                                        <span class="t">Oui</span>
                                                    </dd>
                                                </div>
                                            </dl>
                                            
                                        </div>
                                        <div aria-hidden="true" class="border-blue-600 border-2 bcy u aa as de md rg adt bxo"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-transparent dark:bg-gray-700 rounded-lg shadow-md p-3">
                        <div class="border-yellow-300 jf  afo auo clx">
        <h3 class="text-yellow-300 awa awg awp flex items-center"><MdBackup className='me-2 fa-2x' />
        Solutions de Sauvegarde et Récupération</h3>
        <p class="ku awa awp axs dark:text-white">Services experts pour la sauvegarde, la récupération et la sécurisation de vos données.</p>
    </div>
    <div class="kw abp">
        <div>
            <h4 class="awa awg awp axv dark:text-white">Services de Récupération et Sauvegarde</h4>
            <div class="ab lk">
                <div aria-hidden="true" class="aa as de md rg adt dark:bg-gray-800 bbn bxo"></div>
                <div class="border-yellow-300 sm:border-0 border-2 bcy ab adt dark:bg-gray-800 sm:dark:bg-transparent bbn cdl cei ciq cis">
                    <dl class="acc acg awa awp">
                        <div class="lx yz zf ari arz bxu cat ces">
                            <dt class="aue axs dark:text-white">Récupération de données supprimées</dt>
                            <dd class="lx yz zd cbn cfb">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb text-yellow-300">
                                    <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
                                </svg>
                                <span class="t">Incluse</span>
                            </dd>
                        </div>
                        <div class="lx yz zf ari arz bxu cat ces">
                            <dt class="aue axs dark:text-white">Restauration de partitions perdues</dt>
                            <dd class="lx yz zd cbn cfb">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb text-yellow-300">
                                    <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
                                </svg>
                                <span class="t">Incluse</span>
                            </dd>
                        </div>
                        <div class="lx yz zf ari arz bxu cat ces">
                            <dt class="aue axs dark:text-white">Sauvegarde complète du système</dt>
                            <dd class="lx yz zd cbn cfb">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb text-yellow-300">
                                    <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
                                </svg>
                                <span class="t">Incluse</span>
                            </dd>
                        </div>
                        <div class="lx yz zf ari arz bxu cat ces">
                            <dt class="aue axs dark:text-white">Clonage de disques et partitions</dt>
                            <dd class="lx yz zd cbn cfb">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb text-yellow-300">
                                    <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
                                </svg>
                                <span class="t">Incluse</span>
                            </dd>
                        </div>
                        <div class="lx yz zf ari arz bxu cat ces">
                            <dt class="aue axs dark:text-white">Sauvegarde automatisée et planifiée</dt>
                            <dd class="lx yz zd cbn cfb">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="gx nz sb text-yellow-300">
                                    <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
                                </svg>
                                <span class="t">Incluse</span>
                            </dd>
                        </div>
                    </dl>
                </div>
                <div aria-hidden="true" class="border-yellow-300 border-2 rounded bcy u aa as de md rg adt bxo"></div>
            </div>

        </div>
    </div>
</div>



                    </div>
                </section>


               
            </div>
        </div>

    )
}

export default PricingDetails