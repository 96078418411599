import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import FaqAccordion from "../Components/FaqAccordion";
import PricingCard from "../Components/PricingCard";
import PricingCardInformatique from "../Components/PricingCardInformatique";
import PricingDetails from "../Components/PricingDetailsInformatique";

function Informatique() {
  const { t } = useTranslation();
  return (
    <div className="max-w-7xl mx-auto px-5 py-12">
      <section className="bg-white dark:bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
          <Link to="/nouveautes" className="inline-flex items-center py-1 px-1 pr-4 mb-7 text-sm text-gray-700 bg-gray-100 rounded-full dark:bg-gray-800 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700" role="alert">
            <span className="text-xs bg-sky-600 rounded-full text-white px-4 py-1.5 mr-3">{t('new')}</span>
            <span className="text-sm font-medium">{t('Discover the latest updates from Genius Dev')}</span>
            <svg className="ml-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
          </Link>
          <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">{t('IT Solutions')}</h1>
          <p className="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">{t('Enhance your productivity with our IT solutions.')}</p>
        </div>
      </section>
      <PricingCardInformatique/>
      <PricingDetails/>
      <div className="container mx-auto px-4 mt-10">
  <h2 className="text-2xl font-bold text-gray-900 mb-6 dark:text-white">{t('Cybersecurity and Audits')}</h2>
  <p className="text-gray-700 dark:text-gray-300 mb-4">
    {t('Protect your digital assets with our comprehensive cybersecurity services including audits, malware protection, and firewall setups.')}
  </p>
  <ul className="list-none pl-0 space-y-2 text-gray-600 dark:text-gray-400">
    {[
      'Comprehensive cybersecurity audits to identify potential vulnerabilities.',
      'Advanced malware protection systems and firewall configurations.',
      'Ongoing monitoring and security updates to ensure your systems remain secure.'
    ].map(item => (
      <li key={item} className="flex items-start">
        <svg className="w-6 h-6 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
        </svg>
        {t(item)}
      </li>
    ))}
  </ul>
</div>

<div className="container mx-auto px-4 mt-10">
  <h2 className="text-2xl font-bold text-gray-900 mb-6 dark:text-white">{t('System Optimization and Maintenance')}</h2>
  <p className="text-gray-700 dark:text-gray-300 mb-4">
    {t('Maximize your system’s efficiency with our optimization and regular maintenance services.')}
  </p>
  <ul className="list-none pl-0 space-y-2 text-gray-600 dark:text-gray-400">
    {[
      'Performance optimization to enhance system speed and responsiveness.',
      'Regular maintenance plans to prevent system failures and ensure longevity.'
    ].map(item => (
      <li key={item} className="flex items-start">
        <svg className="w-6 h-6 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
        </svg>
        {t(item)}
      </li>
    ))}
  </ul>
</div>
<div className="container mx-auto px-4 mt-10">
  <h2 className="text-2xl font-bold text-gray-900 mb-6 dark:text-white">{t('Data Recovery and Backup Services')}</h2>
  <p className="text-gray-700 dark:text-gray-300 mb-4">
    {t('Ensure the safety of your data with our reliable recovery and backup services.')}
  </p>
  <ul className="list-none pl-0 space-y-2 text-gray-600 dark:text-gray-400">
    {[
      'Data recovery services for system failures or accidental deletion.',
      'Secure data backup solutions to facilitate easy data recovery.'
    ].map(item => (
      <li key={item} className="flex items-start">
        <svg className="w-6 h-6 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
        </svg>
        {t(item)}
      </li>
    ))}
  </ul>
</div>

<div className="container mx-auto px-4 mt-10">
  <h2 className="text-2xl font-bold text-gray-900 mb-6 dark:text-white">{t('System Optimization Services')}</h2>
  <p className="text-gray-700 dark:text-gray-300 mb-4">
    {t('Expert system analysis and performance enhancements tailored to streamline your IT infrastructure.')}
  </p>
  <ul className="list-none pl-0 space-y-2 text-gray-600 dark:text-gray-400">
    {[
      'Comprehensive system audits to identify and resolve inefficiencies.',
      'Custom hardware and software solutions to enhance system performance.',
      'Workstation customization to meet the unique needs of each user.'
    ].map(item => (
      <li key={item} className="flex items-start">
        <svg className="w-6 h-6 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
        </svg>
        {t(item)}
      </li>
    ))}
  </ul>
</div>


      {/* Dépannage Informatique Section */}
      <div className="container mx-auto px-4">
        <h2 className="text-2xl font-bold text-gray-900 mt-10 mb-6 dark:text-white">{t('Computer Troubleshooting')}</h2>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          {t('Complete technical assistance for hardware upgrades and problem resolution, available for both individuals and businesses.')}
        </p>

        <ul className="list-none pl-0 space-y-2 text-gray-600 dark:text-gray-400">
          {[
            'Rapid and accurate diagnosis of hardware and software issues.',
            'Hardware upgrades including RAM installation, SSD upgrades, and performance optimization.',
            'Repair and troubleshooting of both laptops and desktops to improve productivity.',
            'Remote support for software problem resolution including installation, configuration, and updates.',
            'Data security and implementation of backup solutions to protect vital information.'
          ].map(item => (
            <li key={item} className="flex items-start">
              <svg className="w-6 h-6 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
              {t(item)}
            </li>
          ))}
        </ul>
      </div>
      {/* Dépannage Informatique Section */}
      {/* Password Removal Section */}
      <div className="container mx-auto px-4 mt-10">
        <h2 className="text-2xl font-bold text-gray-900 mb-6 dark:text-white">{t('Password Removal Services')}</h2>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          {t('Complete technical assistance for hardware upgrades and problem resolution, available for both individuals and businesses.')}
        </p>

        <ul className="list-none pl-0 space-y-2 text-gray-600 dark:text-gray-400">
          {[
            'Recovery and removal services for passwords on Android, iOS, and Windows devices, ensuring security and efficiency. A proof of purchase is required before any operation on the device.',
            'Assistance with data recovery following a password loss.',
            'Adherence to security and confidentiality standards for data protection.',
            'Ongoing technical support throughout the process to ensure a transparent service.'
          ].map(item => (
            <li key={item} className="flex items-start">
              <svg className="w-6 h-6 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
              {t(item)}
            </li>
          ))}
        </ul>
      </div>

      <div style={{ margin:'auto', marginTop:80}}>
        <FaqAccordion />
      </div>
      {/* Additional Components and Services */}
      {/* Implement other services like mobile apps development, graphic design, etc., here */}
    </div>
  );
}

export default Informatique;
