import React, { useState } from 'react'
import { FaCheck } from 'react-icons/fa';
import 'flowbite'
import './PricingCard.css'
import '../compiled.css'
import { useTranslation } from 'react-i18next';
import '../i18n';
function PricingCard({isDarkMode}) {
    const [isMonthly, setIsMonthly] = useState(true);  // state pour gérer le type d'affichage
    const [billPlan, setBillPlan] = useState("monthly");
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;
    console.log(currentLanguage)
    const toggleBillPlan = () => {
        setBillPlan(billPlan === "monthly" ? "annually" : "monthly");
    };
    return (
        <div className="max-w-screen-xl mx-auto">

            {/* <div className="flex items-center justify-center mt-10 space-x-4">
                <span className="text-base font-medium">Mois</span>
                <button
                    onClick={toggleBillPlan}
                    className="relative rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    <div className="w-14 h-6 transition bg-blue-600 rounded-full shadow-md outline-none"></div>
                    <div
                        className={`absolute inline-flex items-center justify-center w-4 h-4 transition-all duration-200 ease-in-out transform bg-white rounded-full shadow-sm top-1 left-1 ${billPlan === "monthly" ? "translate-x-0" : "translate-x-8"
                            }`}
                    ></div>
                </button>
                <span className="text-base font-medium" style={{ position: 'relative' }}>Annuel<span class={`${isMonthly ? 'bg-gray-100 text-gray-600' : 'bg-blue-100 text-blue-600'}  text-xs font-medium mr-2 px-2.5 py-1 dark:bg-blue-900 rounded-full dark:text-blue-300 `} style={{ position: 'absolute', top: -10, right: -120 }}>Éonomisez 20%</span></span>
            </div> */}
            <div className='mt-20 mb-20 text-center'>
                {/* <div class="la lx ze">
                    <div class="mb yi zx ads aqm avl awc awg awo bbt bbx bce" id="headlessui-radiogroup-5" role="radiogroup" aria-labelledby="headlessui-label-2">
                        <label class="t" id="headlessui-label-2" role="none">Payment frequency</label>
                        <div class="bg-blue-500  bah xq ads are arp" onClick={() => { setIsMonthly(true); console.log('clicked') }} id="headlessui-radiogroup-option-3" role="radio" aria-checked="true" tabindex="0" data-headlessui-state="checked">
                            <span>Mois</span>
                        </div>
                        <div class="axr xq ads are arp" onClick={() => { setIsMonthly(false); console.log('clicked') }} id="headlessui-radiogroup-option-4" role="radio" aria-checked="false" tabindex="-1" data-headlessui-state="">
                            <span>Annuel <span class={`${isMonthly ? 'bg-gray-100 text-gray-600' : 'bg-blue-100 text-blue-600'}  text-xs font-medium mr-2 px-2.5 py-1 dark:bg-blue-900 rounded-full dark:text-blue-300 `}>Éonomisez 20%</span></span>
                        </div>
                    </div>
                </div> */}

                <div className="la lx ze" style={{position: 'relative', zIndex: 9}}>
                    <div className="mb yi zx ads aqm avl awc awg awo bbt bbx bce" id="headlessui-radiogroup-5" role="radiogroup" aria-labelledby="headlessui-label-2">
                        <label className="t" id="headlessui-label-2" role="none">Payment frequency</label>
                        <div
                            className={`xq ads are arp transition-all ease-in-out duration-300 ${isMonthly ? 'bg-blue-500 text-white' : ''}`}
                            onClick={() => { setIsMonthly(true); console.log('clicked') }}
                            id="headlessui-radiogroup-option-3"
                            role="radio"
                            aria-checked={isMonthly}
                            tabindex="0"
                        >
                            <span>{t('Mois')}</span>
                        </div>
                        <div
                            className={`xq ads are arp transition-all ease-in-out duration-300 ${isMonthly ? '' : 'bg-blue-500 text-white'}`}
                            onClick={() => { setIsMonthly(false); console.log('clicked') }}
                            id="headlessui-radiogroup-option-4"
                            role="radio"
                            aria-checked={!isMonthly}
                            tabindex="-1"
                        >
                            <span className='px-3' style={{ position: 'relative' }}>{t('Annuel')} <span className={`${isMonthly ? 'bg-gray-100 text-gray-600' : 'bg-blue-100 text-blue-600'} text-xs font-medium mr-2 px-2.5 py-1  rounded-full  `} style={{ position: 'absolute', top: -20, right: currentLanguage === 'en' ? -75 : -115}}>{t('Économisez 20%')}</span></span>
                        </div>
                    </div>
                </div>


                {/* <button style={{ position: 'relative', zIndex: 8 }}
                    onClick={() => { setIsMonthly(true); console.log('clicked') }}
                    className={` me-2 mt-20 ${isMonthly ? 'text-blue-600 font-semibold ' : 'gray'}`}
                >
                    Tarifs Mensuel
                </button>
                |

                <button style={{ position: 'relative', zIndex: 8 }}
                    onClick={() => { setIsMonthly(false); console.log('clicked') }}
                    className={` ms-2 ${isMonthly ? 'gray' : 'text-blue-600 font-semibold '}`}
                >
                    Tarifs Annuel <span class={`${isMonthly ? 'bg-gray-100 text-gray-600' : 'bg-blue-100 text-blue-600'}  text-xs font-medium mr-2 px-2.5 py-1 dark:bg-blue-900 rounded-full dark:text-blue-300 `}>Éonomisez 20%</span>
                </button> */}
            </div>
            <div className=' mt-5 flex flex-wrap justify-around  mx-auto'>
                <div class={`w-full  ${isDarkMode? 'shadow shadow-light dark:hover:shadow-light-hover' : ' shadow light:hover:shadow-light-hover '}  transition-shadow duration-300    md:w-1/2 lg:w-1/2 xl:w-1/5 max-w-sm p-4 mb-5 me-3 bg-white border border-gray-200 rounded-lg sm:p-8 dark:bg-gray-800 dark:border-gray-700`}>
                    <h5 class="mb-4 text-xl font-medium text-gray-500 dark:text-gray-400">Basique <br /><small className='text-sm text-gray-400'>Pour les débutant avec un planning de base </small></h5>
                    
                    <div class="flex items-baseline text-gray-900 dark:text-white">
                        {/* <span class="text-3xl font-semibold">$</span> */}
                        {/* <span class="text-5xl font-extrabold tracking-tight">Toujours Gratuit</span>: */}
                        <span class="ml-1 text-xl font-bold text-blue-800 dark:text-sky-600">Toujours Gratuit</span>
                    </div>
                    <ul role="list" class="space-y-5 my-7">
                        <li class="flex space-x-3 items-center">
                            <i className="bi bi-check text-xl text-blue-700"></i>

                            {/* </svg> */}
                            <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 lg:text-sm">Connectez un calendrier</span>
                        </li>
                        <li class="flex space-x-3">
                        <i className="bi bi-check text-xl text-blue-700"></i>

                            <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 lg:text-sm">Créez un type d'évènement actif et planifiez un nombre illimité de réunions</span>
                        </li>
                        <li class="flex space-x-3">
                        <i className="bi bi-check text-xl text-blue-700"></i>

                            <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 lg:text-sm">Personnalisez votre lien de réservation et marquez votre page</span>
                        </li>
                        <li class="flex space-x-3  decoration-gray-500">
                        <i className="bi bi-check text-xl text-blue-700"></i>

                            <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 lg:text-sm">Envoyez des notifications d'évènements automatisées</span>
                        </li>
                        <li class="flex space-x-3  decoration-gray-500">
                        <i className="bi bi-check text-xl text-blue-700"></i>

                            <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 lg:text-sm">Ajouter GeniusCalendar à votre site</span>
                        </li>
                        <li class="flex space-x-3  decoration-gray-500">
                        <i className="bi bi-check text-xl text-blue-700"></i>

                            <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 lg:text-sm">Connectez-vous à Google Meet, Microsoft Teams, Zoom, Slack et autres</span>
                        </li>
                        <li class="flex space-x-3  decoration-gray-500">
                        <i className="bi bi-check text-xl text-blue-700"></i>

                            <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 lg:text-sm">Accédez au centre d'aide et à l'assistance par e-mail 24h/24 et 7j/7</span>
                        </li>
                    </ul>
                    <button type="button" class="text-gray-600 dark:text-white border border-gray-200 bg-blue-600 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-200 dark:focus:ring-blue-900 font-medium rounded-lg text-sm px-5 py-2.5 flex justify-center w-50 mx-auto text-center">Choisir ce plan</button>
                </div>
                <div class={`w-full  ${isDarkMode? 'shadow shadow-light dark:hover:shadow-light-hover' : ' shadow light:hover:shadow-light-hover '}  transition-shadow duration-300  md:w-1/2 lg:w-1/2 xl:w-1/5 max-w-sm p-4 mb-5 me-3 bg-white border border-gray-200 rounded-lg sm:p-8 dark:bg-gray-800 dark:border-gray-700`}>
                    <h5 class="mb-4 text-xl font-medium text-gray-500 dark:text-gray-400">Essentiel <br /><small className='text-sm text-gray-400'>Pour les personnes ayant des besoins de planification plus sophistiqués </small></h5>
                    <div class="flex items-baseline mb-5 text-gray-900 dark:text-white">
                        <span class="text-5xl font-semibold tracking-tight">{isMonthly ? '10' : '8'}</span>
                        <span class="text-3xl font-semibold">€</span>
                        <span class="ml-1 text-xl font-normal text-gray-500 dark:text-gray-400">{isMonthly ? '/mois' : '/an'}</span>
                    </div>
                    <small className='mt-5 text-blue-800 dark:text-blue-500 font-bold'>Fonctionnalités de base plus</small>
                    <ul role="list" class="space-y-5 mb-7 mt-2">
                        <li class="flex space-x-3 items-center">
                        <i className="bi bi-check text-xl text-blue-700"></i>

                            <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 lg:text-sm">Connectez six calendriers</span>
                        </li>
                        <li class="flex space-x-3">
                            <svg class="flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 lg:text-sm">Créer des types d'événements illimités</span>
                        </li>
                        <li class="flex space-x-3">
                            <svg class="flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 lg:text-sm">Créer des évènements de groupe</span>
                        </li>
                        <li class="flex space-x-3  decoration-gray-500">
                            <svg class="flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 lg:text-sm">Envoyer à vos invités des rappels et des suivis par e-mail</span>
                        </li>
                        <li class="flex space-x-3  decoration-gray-500">
                            <svg class="flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 lg:text-sm">Connectez-vous avec le support de chat en direct</span>
                        </li>
                        {/* <li class="flex space-x-3 line-through decoration-gray-500">
                            <svg class="flex-shrink-0 w-4 h-4 text-gray-400 dark:text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span class="text-base font-normal leading-tight text-gray-500">Complete documentation</span>
                        </li>
                        <li class="flex space-x-3 line-through decoration-gray-500">
                            <svg class="flex-shrink-0 w-4 h-4 text-gray-400 dark:text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span class="text-base font-normal leading-tight text-gray-500">24×7 phone & email support</span>
                        </li> */}
                    </ul>
                    <button type="button" class="text-gray-600 dark:text-white border border-gray-200 bg-blue-600 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-200 dark:focus:ring-blue-900 font-medium rounded-lg text-sm px-5 py-2.5 flex justify-center w-50 mx-auto text-center">Choisir ce plan</button>
                </div>
                <div class={`w-full  ${isDarkMode? 'shadow light:hover:shadow-professional-plan-hover' : ' shadow light:hover:shadow-professional-plan-hover '}  transition-shadow duration-300   md:w-1/2 lg:w-1/2 xl:w-1/5  max-w-sm p-4 mb-5 me-3 bg-white border-2 border-blue-600 rounded-lg sm:p-8 dark:bg-gray-800 dark:border-blue-600`}>

                {/* <div class="w-full md:w-1/2 lg:w-1/5 max-w-sm p-4 me-3 mb-5 bg-white border border-blue-400 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-blue-700 border-2"> */}
                    <h5 class="mb-4 text-xl font-medium text-blue-500 ">Professionnel <span class="bg-blue-100 text-blue-600 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">Populaire</span>  <br /><small className='text-xs text-gray-400'>Pour les petites équipes qui ont besoin d'une plus grande personnalisation et de rapport</small>
                    </h5>
                    <div class="flex items-baseline mb-5 text-gray-900 dark:text-white">
                        <span class="text-5xl font-semibold tracking-tight">{isMonthly ? '15' : '12'}</span>
                        <span class="text-3xl font-semibold">€</span>
                        <span class="ml-1 text-xl font-normal text-gray-500 dark:text-gray-400">{isMonthly ? '/mois' : '/an'}</span>
                    </div>
                    <small className='mt-5 text-blue-800 font-bold  dark:text-blue-500'>Plus de fonctionnalités essentielles</small>

                    <ul role="list" class="space-y-5 mb-7 mt-2">
                        <li class="flex space-x-3 items-center">
                            <svg class="flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span class="text-sm font-normal leading-tight text-gray-500 dark:text-gray-400 ">Créer des types d'événements collectifs</span>
                        </li>
                        <li class="flex space-x-3">
                            <svg class="flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span class="text-sm font-normal leading-tight text-gray-500 dark:text-gray-400 ">Acheminer avec les formulaires d'acheminement de GeniusCalendar</span>
                        </li>
                        <li class="flex space-x-3">
                            <svg class="flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span class="text-sm font-normal leading-tight text-gray-500 dark:text-gray-400 ">Créez des flux de travail automatisés avec des notifications et rappels</span>
                        </li>
                        <li class="flex space-x-3 decoration-gray-500">
                        <svg class="flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span class=" font-normal leading-tight text-gray-500 dark:text-gray-400 text-sm">Afficher les analyses et les informations</span>
                        </li>
                        <li class="flex space-x-3 decoration-gray-500">
                        <svg class="flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span class=" font-normal leading-tight text-gray-500 dark:text-gray-400 text-sm">Créer des événements gérés et des groupes d'utilisateurs, et affecter des administrateurs de groupe</span>
                        </li>
                        <li class="flex space-x-3 decoration-gray-500">
                        <svg class="flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span class=" font-normal leading-tight text-gray-500 dark:text-gray-400 text-sm">Personnalisez les couleurs, les logos et l'image de marque de GeniusCalendar sur votre site Web</span>
                        </li>
                        <li class="flex space-x-3 decoration-gray-500">
                        <svg class="flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span class=" font-normal leading-tight text-gray-500 dark:text-gray-400 text-sm">Connectez-vous à Gmail, Outlook, Hubspot, Paypal et des centaines d'autres</span>
                        </li>
                    </ul>
                    <button type="button" class="text-gray-600 dark:text-white border border-gray-200 bg-blue-600 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-200 dark:focus:ring-blue-900 font-medium rounded-lg text-sm px-5 py-2.5 flex justify-center w-50 mx-auto text-center">Choisir ce plan</button>
                </div>
                <div class={`w-full  ${isDarkMode? 'shadow shadow-light dark:hover:shadow-light-hover' : ' shadow light:hover:shadow-light-hover '}  transition-shadow duration-300  md:w-1/2 lg:w-1/2 xl:w-1/5  max-w-sm p-4 mb-5 me-3 bg-white border border-gray-200 rounded-lg sm:p-8 dark:bg-gray-800 dark:border-gray-700`}>
                    <h5 class="mb-4 text-xl font-medium text-gray-500 dark:text-gray-400">Ultimate</h5>
                    <div class="flex mb-5 items-baseline text-gray-900 dark:text-white">
                        <span class="text-5xl font-semibold tracking-tight">{isMonthly ? '20' : '16'}</span>
                        <span class="text-3xl font-semibold">€</span>
                        <span class="ml-1 text-xl font-normal text-gray-500 dark:text-gray-400">{isMonthly ? '/mois' : '/an'}</span>
                    </div>
                    <small className='mt-5 text-blue-800 font-bold dark:text-blue-500'>Plus de fonctionnalités essentielles</small>
                    <ul role="list" class="space-y-5 mb-7 mt-2">
                        <li class="flex space-x-3 items-center">
                            <svg class="flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 lg:text-sm">Créer des types d'événements circulaires</span>
                        </li>
                        <li class="flex space-x-3">
                            <svg class="flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 lg:text-sm">Routage avec les formulaires Marketo, HubSpot ou Pardot</span>
                        </li>
                        <li class="flex space-x-3">
                            <svg class="flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">Connectez-vous à Salesforce</span>
                        </li>
                        <li class="flex space-x-3 decoration-gray-500">
                        <svg class="flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">Verrouillez et synchronisez les événements gérés au sein de votre équipe</span>
                        </li>
                        <li class="flex space-x-3 decoration-gray-500">
                        <svg class="flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span class="text-base leading-tight text-gray-500 dark:text-gray-400">Générateur de factures illimités</span>
                        </li>
                        <li class="flex space-x-3 decoration-gray-500">
                        <svg class="flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">Messagerie instantanées</span>
                        </li>
                        <li class="flex space-x-3 decoration-gray-500">
                        <svg class="flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">Personnalisation complète de vos calendriers</span>
                        </li>
                    </ul>
                    <button type="button" class="text-gray-600 dark:text-white border border-gray-200 bg-blue-600 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-200 dark:focus:ring-blue-900 font-medium rounded-lg text-sm px-5 py-2.5 flex justify-center w-50 mx-auto text-center">Choisir ce plan</button>
                </div>

            </div>
        </div>

    )
}

export default PricingCard