import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from 'react-router-dom';
import 'flowbite'
import {

  IconButton,
} from "@material-tailwind/react";
import './Navbar.css'
// import { useTranslation } from 'react-i18next';
import { useLanguage } from "./LanguageContext";
import { useTranslation } from 'react-i18next';

import '../i18n';
import LanguageSwitcher from "./LanguageSwitcher";
import { FaLaptop, FaSitemap, FaAppStoreIos, FaMobileAlt, FaToolbox } from 'react-icons/fa';


function NavbarDefault({ darkMode, toggleDarkMode }) {
  const [openNav, setOpenNav] = React.useState(true);
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isCompanyMenuOpen, setCompanyMenuOpen] = useState(false);
  const companyMenuRef = useRef(null);
  const toggleRef = useRef(null); // Référence au bouton qui contrôle le menu
  const [menuWidth, setMenuWidth] = useState('auto');
  useEffect(() => {
    const updateMenuWidth = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 640) { // 640px est un point de rupture commun pour les appareils mobiles
        setMenuWidth('90%');
      } else {
        setMenuWidth('auto');
      }
    };

    window.addEventListener('resize', updateMenuWidth);
    updateMenuWidth(); // Initial check

    return () => window.removeEventListener('resize', updateMenuWidth);
  }, []);
  useEffect(() => {
    function handleClickOutside(event) {
        if (toggleRef.current && toggleRef.current.contains(event.target)) {
            return; // Click on button should be handled by toggle function
        }
        if (!companyMenuRef.current || !companyMenuRef.current.contains(event.target)) {
            setCompanyMenuOpen(false);
        }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
}, []);

  const handleMenuClick = () => {
      setCompanyMenuOpen(prev => !prev);
  };

  const handleLinkClick = () => {
      setCompanyMenuOpen(false);
  };
  const toggleDropdown = () => setIsOpen(!isOpen);

  const closedStyle = {
    position: 'absolute',
    inset: '0px auto auto 0px',
    margin: '0px',
    transform: 'translate3d(492px, 62px, 0px)'
};

const openStyle = {
  position: 'absolute',
  left: 0, 
  inset: '0px auto auto 0px',
  margin: '0px',
  transform: 'translate3d(-20px, 46px, 0px)'
};
  // const { changeLanguage } = useLanguage();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  // const changeLanguage = (lng) => {
  //   i18n.changeLanguage(lng);
  // };
  const dropdownStyles = {
    // position: 'absolute',
    // top: '100%',
    // right: 0,
    backgroundColor: '#fff',
    boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
    zIndex: 1,
    opacity: showDropdown ? 1 : 0,
    transition: 'opacity 0.3s ease'
  };

  const listItemStyles = {
    padding: '8px 16px',
    cursor: 'pointer'
  };
  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false),
    );
  }, []);
  // const toggleDarkMode = () => {
  //   setDarkMode(!darkMode);
  // };
  React.useEffect(() => {
    setOpenNav(false);
  }, [location.pathname]);

  // const handleLanguageChange = (e) => {
  //   changeLanguage(e.target.value);
  // };

  const [showFirstSwitcher, setShowFirstSwitcher] = useState(window.innerWidth < 1024);
  const [showSecondSwitcher, setShowSecondSwitcher] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    const handleResize = () => {
      setShowFirstSwitcher(window.innerWidth < 1024);
      setShowSecondSwitcher(window.innerWidth >= 1024);
    };

    window.addEventListener('resize', handleResize);

    // Nettoyer l'écouteur d'événements lorsque le composant est démonté
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (

    <nav className="bg-gray-50 border-gray-200 dark:bg-gray-900 bg-opacity-90  dark:bg-opacity-90 dark:border-gray-700">
      <div className="max-w-screen-lg flex flex-wrap items-center justify-between mx-auto p-4">
        <Link to="/" className="flex items-center">
        <img src="/images/Logo-maker-project-4.png" alt="Genius Dev" style={{width:'auto',height:'7vh'}}/>
          
          <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
            
            {/* {t('GeniusCalendar')} */}
          </span>
        </Link>
        {/* Dark mode button positioned at top-right */}
        <div className="flex items-center">

          <IconButton
            variant="text"
            className="ml-2 me-2 h-6 w-6 hover:bg-transparent focus:bg-transparent active:bg-transparent flex justify-center items-center lg:hidden xl:hidden"
            ripple={false}
            onClick={toggleDarkMode}
          >
            {darkMode ? (
              <svg
                id="theme-toggle-dark-icon"
                class="w-5 h-5  text-white"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"
                ></path>
              </svg>
            ) : (
              <svg
                id="theme-toggle-dark-icon"
                className="w-5 h-5  text-black"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                  fillRule="evenodd"
                  clipRule="evenodd"
                ></path>
              </svg>
            )}
          </IconButton>

          <button data-collapse-toggle="navbar-multi-level" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2  me-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-multi-level" onClick={() => setOpenNav(!openNav)}
            aria-expanded="false">
            <span className="sr-only">Open main menu</span>
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
            </svg>

          </button>
          <LanguageSwitcher showSwitcherLanguage={showFirstSwitcher} />
        </div>
        <div className={`${openNav ? 'block' : 'hidden'} w-full lg:block lg:w-auto`} id="navbar-multi-level">
          <ul className="flex flex-col font-medium p-4 lg:p-0 mt-4  rounded-lg items-center  lg:flex-row lg:space-x-8 lg:mt-0 lg:border-0  dark:border-gray-700">

            <li className={location.pathname === '/' ? 'text-sky-500 border-b-2 border-sky-500 list-none	' : 'list-none	'}>
              <Link to="/" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-white lg:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent">{t('Accueil')}</Link>
            </li>
            <li >
              <button className="relative block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 lg:hover:bg-transparent w-auto lg:border-0 hover:text-blue-500 lg:p-0 dark:text-white lg:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent" onClick={() => setCompanyMenuOpen(!isCompanyMenuOpen)}>Nos Solutions</button>
              {isCompanyMenuOpen && (
    <div ref={companyMenuRef}
    style={{
      position: 'absolute',
      left: '50%',
      width: menuWidth,
      transform: 'translateX(-50%)',
      overflowX: 'hidden',
      overflowY: 'scroll',
      transition: 'all 0.5s ease-in-out',
      maxHeight: isCompanyMenuOpen ? '300px' : '0',
      borderRadius: '20px',
      margin: 'auto',
      display: 'block',
      scrollbarGutter: '2px', // For Firefox
      scrollbarWidth: '2px', // For Firefox
      scrollbarThumb: '2px', // For Firefox
      scrollbarColor: '#c1c1c1 #f9f9f9' // For Firefox
      
    }}
               className="absolute custom-scrollbar bg-gray-50  mt-2  dark:bg-gray-800 rounded shadow-xl z-10" >
                  <div className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm/6 hover:bg-gray-100 w-auto mb-2">
                    <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-gray-300">
                    <FaLaptop className="h-6 w-6 text-gray-600 group-hover:text-sky-500" size={20} />

                    </div>
                    <Link to='/solution/informatique' className="flex-auto">
                      <div  className="block font-semibold dark:text-white hover:dark:text-gray-800">
                        Informatique 
                        <span className="absolute inset-0"></span>
                      </div>
                      <div className="text-gray-500">Découvrez nos diverses prestations informatique.</div>
                    </Link>
                  </div>
                  <div className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm/6 hover:bg-gray-100 w-auto mb-2">
                    <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-gray-300">
                    <FaSitemap className="h-6 w-6 text-gray-600 group-hover:text-sky-500" size={20} />

                    </div>
                    <div className="flex-auto">
                      <a href="#" className="block font-semibold dark:text-white hover:dark:text-gray-800">
                        Développement Web 
                        <span className="absolute inset-0"></span>
                      </a>
                      <div className="text-gray-500">Votre site web sur mesure.</div>
                    </div>
                  </div>
                  <div className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm/6 hover:bg-gray-100 w-auto mb-2">
                    <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-gray-300">
                    <FaAppStoreIos className="h-6 w-6 text-gray-600 group-hover:text-sky-500" size={20} />

                    </div>
                    <div className="flex-auto">
                      <a href="#" className="block font-semibold dark:text-white hover:dark:text-gray-800">
                      Développement Logiciel 
                        <span className="absolute inset-0"></span>
                      </a>
                      <div className="text-gray-500">Votre logiciel sur mesure.</div>
                    </div>
                  </div>
                  <div className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm/6 hover:bg-gray-100 w-auto mb-2">
                    <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-gray-300">
                    <FaMobileAlt className="h-6 w-6 text-gray-600 group-hover:text-sky-500" size={20} />

                    </div>
                    <div className="flex-auto">
                      <a href="#" className="block font-semibold dark:text-white hover:dark:text-gray-800">
                      Développement Mobile 
                        <span className="absolute inset-0"></span>
                      </a>
                      <div className="text-gray-500">Votre application mobile sur mesure.</div>
                    </div>
                  </div>
                  <div className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm/6 hover:bg-gray-100 w-auto mb-2">
                    <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-gray-300">
                    <FaToolbox className="h-6 w-6 text-gray-600 group-hover:text-sky-500" size={20} />

                    </div>
                    <div className="flex-auto">
                      <Link to="/solution/depannage" className="block font-semibold dark:text-white hover:dark:text-gray-800">
                      Dépannage 
                        <span className="absolute inset-0"></span>
                      </Link>
                      <div className="text-gray-500">Besoin d'un dépannage informatique ? </div>
                    </div>
                  </div>
                </div>
              )}
            </li>
            <li className={location.pathname === '/services' ? 'text-sky-500 border-b-2 border-sky-500' : ''}>
              <Link to="/services" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-white lg:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent">Services</Link>
            </li>
            <li className={location.pathname === '/tarifs' ? 'text-sky-500 border-b-2 border-sky-500' : ''}>
              <Link to="/tarifs" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-white lg:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent">{t('Prix')}</Link>
            </li>
            <li className={location.pathname === '/contact' ? 'text-sky-500 border-b-2 border-sky-500' : ''}>
              <Link to="/contact" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-white lg:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent">Contact</Link>
            </li>
            <li>
              <a href="#" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-white lg:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent">{t('Connexion')}</a>
            </li>
            <IconButton
              variant="text"
              className="ml-2 h-6 w-6 hover:bg-transparent focus:bg-transparent active:bg-transparent hidden lg:flex lg:flex xl:flex"
              ripple={false}
              onClick={toggleDarkMode}
            >
              {darkMode ? (
                <svg
                  id="theme-toggle-dark-icon"
                  className="w-5 h-5  text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"
                  ></path>
                </svg>
              ) : (
                <svg
                  id="theme-toggle-dark-icon"
                  className="w-5 h-5  text-black"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                    fillRule="evenodd"
                    clipRule="evenodd"
                  ></path>
                </svg>
              )}
            </IconButton>
            <LanguageSwitcher showSwitcherLanguage={showSecondSwitcher} />
          </ul>

        </div>
      </div>
    </nav>

  );
}

export default NavbarDefault;