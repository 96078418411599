import React, { useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import 'flowbite'
import {

  IconButton,
} from "@material-tailwind/react";
import './AdminNavbar.css'
// import { useTranslation } from 'react-i18next';
import { useLanguage } from "../LanguageContext";
import { useTranslation } from 'react-i18next';

import '../../i18n';
import LanguageSwitcher from "../LanguageSwitcher";


function AdminNavbar({ darkMode, toggleDarkMode }) {
  const [openNav, setOpenNav] = React.useState(true);
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);
  const closedStyle = {
    position: 'absolute',
    inset: '0px auto auto 0px',
    margin: '0px',
    transform: 'translate3d(492px, 62px, 0px)'
};

const openStyle = {
  position: 'absolute',
  left: 0, 
  inset: '0px auto auto 0px',
  margin: '0px',
  transform: 'translate3d(-20px, 46px, 0px)'
};
  // const { changeLanguage } = useLanguage();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  // const changeLanguage = (lng) => {
  //   i18n.changeLanguage(lng);
  // };
  const dropdownStyles = {
    // position: 'absolute',
    // top: '100%',
    // right: 0,
    backgroundColor: '#fff',
    boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
    zIndex: 1,
    opacity: showDropdown ? 1 : 0,
    transition: 'opacity 0.3s ease'
  };

  const listItemStyles = {
    padding: '8px 16px',
    cursor: 'pointer'
  };
  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false),
    );
  }, []);
  // const toggleDarkMode = () => {
  //   setDarkMode(!darkMode);
  // };
  React.useEffect(() => {
    setOpenNav(false);
  }, [location.pathname]);

  // const handleLanguageChange = (e) => {
  //   changeLanguage(e.target.value);
  // };

  const [showFirstSwitcher, setShowFirstSwitcher] = useState(window.innerWidth < 1024);
  const [showSecondSwitcher, setShowSecondSwitcher] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    const handleResize = () => {
      setShowFirstSwitcher(window.innerWidth < 1024);
      setShowSecondSwitcher(window.innerWidth >= 1024);
    };

    window.addEventListener('resize', handleResize);

    // Nettoyer l'écouteur d'événements lorsque le composant est démonté
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  <IconButton
    variant="text"
    className="ml-2 h-6 w-6 hover:bg-transparent focus:bg-transparent active:bg-transparent"
    ripple={false}
    onClick={toggleDarkMode}
  >
    {darkMode ? (
      <svg
        id="theme-toggle-dark-icon"
        class="w-5 h-5  text-black"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"
        ></path>
      </svg>
    ) : (
      <svg
        id="theme-toggle-dark-icon"
        class="w-5 h-5  text-black"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
          fillRule="evenodd"
          clipRule="evenodd"
        ></path>
      </svg>
    )}
  </IconButton>
  return (

    <nav className="bg-white border-gray-200 dark:bg-gray-900 dark:bg-opacity-75 dark:border-gray-700">
      <div className="max-w-screen-lg flex flex-wrap items-center justify-between mx-auto p-4">
        <Link to="/" className="flex items-center">
          <img
            src="images/calendar-icon.png"
            className="h-10 mr-3"
            alt="Flowbite Logo"
          />
          <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
            {t('GeniusCalendar')}
          </span>
        </Link>
        {/* Dark mode button positioned at top-right */}
        <div className="flex items-center">

          <IconButton
            variant="text"
            className="ml-2 me-2 h-6 w-6 hover:bg-transparent focus:bg-transparent active:bg-transparent flex justify-center items-center lg:hidden xl:hidden"
            ripple={false}
            onClick={toggleDarkMode}
          >
            {darkMode ? (
              <svg
                id="theme-toggle-dark-icon"
                class="w-5 h-5  text-white"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"
                ></path>
              </svg>
            ) : (
              <svg
                id="theme-toggle-dark-icon"
                className="w-5 h-5  text-black"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                  fillRule="evenodd"
                  clipRule="evenodd"
                ></path>
              </svg>
            )}
          </IconButton>

          <button data-collapse-toggle="navbar-multi-level" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2  me-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-multi-level" onClick={() => setOpenNav(!openNav)}
            aria-expanded="false">
            <span className="sr-only">Open main menu</span>
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
            </svg>

          </button>
          <LanguageSwitcher showSwitcherLanguage={showFirstSwitcher} />
        </div>
        <div className={`${openNav ? 'block' : 'hidden'} w-full lg:block lg:w-auto`} id="navbar-multi-level">
          <ul className="flex flex-col font-medium p-4 lg:p-0 mt-4  rounded-lg items-center  lg:flex-row lg:space-x-8 lg:mt-0 lg:border-0  dark:border-gray-700">

            <li className={location.pathname === '/' ? 'text-sky-500 border-b-2 border-sky-500' : ''}>
              <Link to="/" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-white lg:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent">{t('Accueil')}</Link>
            </li>
            <li style={{position: 'relative', textAlign: 'center'}}>
              <button id="dropdownNavbarLink" data-dropdown-toggle="dropdownNavbar" class="flex items-center justify-between w-full py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 md:w-auto dark:text-white md:dark:hover:text-blue-500 dark:focus:text-white dark:border-gray-700 dark:hover:bg-gray-700 md:dark:hover:bg-transparent" onClick={toggleDropdown}>Dropdown <svg class="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
              </svg></button>
              <div id="dropdownNavbar" class={`z-10 ${isOpen ? 'block' : 'hidden'} font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600`} style={isOpen ? openStyle : closedStyle}>
                <ul class="py-2 text-sm text-gray-700 dark:text-gray-400" aria-labelledby="dropdownLargeButton">
                  <li>
                    <Link to="/dashboard" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Dashboard</Link>
                  </li>
                  <li>
                    <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Settings</a>
                  </li>
                  <li>
                    <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Earnings</a>
                  </li>
                </ul>
                <div class="py-1">
                  <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white">Sign out</a>
                </div>
              </div>
            </li>
            <li className={location.pathname === '/services' ? 'text-sky-500 border-b-2 border-sky-500' : ''}>
              <Link to="/services" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-white lg:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent">Services</Link>
            </li>
            <li className={location.pathname === '/tarifs' ? 'text-sky-500 border-b-2 border-sky-500' : ''}>
              <Link to="/tarifs" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-white lg:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent">{t('Prix')}</Link>
            </li>
            <li className={location.pathname === '/contact' ? 'text-sky-500 border-b-2 border-sky-500' : ''}>
              <Link to="/contact" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-white lg:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent">Contact</Link>
            </li>
            <li>
              <a href="#" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-white lg:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent">{t('Connexion')}</a>
            </li>
            <IconButton
              variant="text"
              className="ml-2 h-6 w-6 hover:bg-transparent focus:bg-transparent active:bg-transparent hidden lg:flex lg:flex xl:flex"
              ripple={false}
              onClick={toggleDarkMode}
            >
              {darkMode ? (
                <svg
                  id="theme-toggle-dark-icon"
                  className="w-5 h-5  text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"
                  ></path>
                </svg>
              ) : (
                <svg
                  id="theme-toggle-dark-icon"
                  className="w-5 h-5  text-black"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                    fillRule="evenodd"
                    clipRule="evenodd"
                  ></path>
                </svg>
              )}
            </IconButton>
            <LanguageSwitcher showSwitcherLanguage={showSecondSwitcher} />
          </ul>

        </div>
      </div>
    </nav>

  );
}

export default AdminNavbar;