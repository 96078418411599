import React from 'react'
import { useTranslation } from 'react-i18next';

function AdminFooter() {
    const { t } = useTranslation();

    return (
        <div>
            <footer class="" aria-labelledby="footer-heading" style={{position: 'sticky', bottom: 0}}>
                <h2 id="footer-heading" class="t">Footer</h2>
                <div class="gx ua ark asy aus chh ddh dfk">
                    
                    <div class="la afm aga avg bxc cun flex items-center justify-between">
                        <p class="awc awo axr">© 2023 GeniusCalendar, Inc. Tous droits réservé.</p>
                        <p class="awc awo axr">Développer par <a href="https://imdadtaieb.com" className='text-sky-500 font-semibold'>Imdad Taieb</a>.</p>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default AdminFooter