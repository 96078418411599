import React, { useState } from 'react';

const FaqAccordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "Comment puis-je garantir que mon réseau est sécurisé contre les cyberattaques ?",
      answer: "La sécurisation de votre réseau commence par une évaluation détaillée de votre infrastructure actuelle, suivie de l'implémentation de solutions de sécurité multicouches comprenant des pare-feu avancés, des systèmes de détection et de prévention des intrusions, ainsi que des politiques de sécurité régulièrement mises à jour et des formations de sensibilisation à la sécurité pour tous les utilisateurs."
    },
    {
      question: "Quels types de plans de sauvegarde recommandez-vous pour protéger les données critiques de mon entreprise ?",
      answer: "Nous recommandons une stratégie de sauvegarde 3-2-1 : trois copies de vos données, sur deux types de stockage différents, dont une copie hors site. La sauvegarde doit être effectuée de manière régulière et testée fréquemment pour assurer la fiabilité en cas de restauration nécessaire suite à un incident."
    },
    {
      question: "Quelle est la fréquence idéale pour la maintenance préventive de mon parc informatique ?",
      answer: "Une maintenance préventive efficace est généralement effectuée au moins une fois par trimestre pour s'assurer que tous les systèmes fonctionnent à leur performance optimale et pour corriger les problèmes avant qu'ils ne causent des pannes. Cette fréquence peut varier en fonction de l'âge de l'équipement et de l'intensité d'utilisation."
    },
    {
      question: "Comment puis-je améliorer la performance de mon système informatique sans investir dans du nouveau matériel ?",
      answer: "L'optimisation de système peut souvent être réalisée par une reconfiguration logicielle, des mises à jour régulières du système et des applications, ainsi que par la défragmentation des disques durs et l'épuration de fichiers inutilisés. Un audit de votre système actuel peut révéler des améliorations potentielles sans nécessiter de coûteuses mises à niveau matérielles."
    },
    {
      question: "Que faire en cas de panne inattendue de mon équipement informatique ?",
      answer: "En cas de panne, il est crucial de disposer d'un plan de réponse d'urgence qui inclut des contacts de support technique immédiat, comme notre service de dépannage disponible 24/7. Il est également sage de maintenir des sauvegardes régulières et un inventaire précis du matériel pour accélérer les réparations ou les remplacements."
    }
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="max-w-4xl mx-auto p-5 mt-20 rounded-lg bg-white dark:bg-gray-900">
      {faqs.map((faq, index) => (
        <div key={index} className="mb-4 rounded-lg rounded-t-lg shadow-lg">
          <div
            className={`p-4 cursor-pointer flex justify-between items-center font-semibold text-lg text-gray-800 dark:text-gray-200 ${activeIndex === index ? 'bg-gray-200 dark:bg-gray-700 rounded-lg' : 'bg-gray-100 dark:bg-gray-800 rounded-lg'}`}
            onClick={() => toggleFAQ(index)}
          >
            {faq.question}
            <span className="text-xl">{activeIndex === index ? '−' : '+'}</span>
          </div>
          <div
            className={`transition-max-height duration-700 ease-in-out overflow-hidden ${activeIndex === index ? 'max-h-screen text-center' : 'max-h-0 text-center'}`}
          >
            <p className="text-gray-600 dark:text-gray-400 p-4">
              {faq.answer}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FaqAccordion;
