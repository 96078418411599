import React, { useState, useEffect } from 'react';
import '../bootstrap-icons.css';
import '../boxicons.min.css';
import './ChatBox.css';

function ChatBox({ isDarkMode }) {
  const [showChat, setShowChat] = useState(false);

  const toggleChat = (event) => {
    event.stopPropagation();  // Arrête la propagation de l'événement
    console.log("Toggle Chat Called");
    setShowChat(!showChat);
  };

  const handleClickOutside = (event) => {
    console.log("Handle Click Outside Called");
    const chatBoxElement = document.getElementById('chatBox');
    const anotherButtonElement = document.getElementById('minus-btn');
    const yetAnotherButtonElement = document.getElementById('times-closed');
    if (chatBoxElement && !chatBoxElement.contains(event.target) &&
      event.target !== anotherButtonElement &&
      event.target !== yetAnotherButtonElement) {
      setShowChat(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className='chat-container'>
      <button
        className='chatbox-btn chat-button'
        style={{ position: 'fixed', bottom: '0', right: '0' }}
        onClick={toggleChat}
      >
        <div className='wrap-icon icon-1'>
          <i className={`bi ${showChat ? 'bi-x' : 'bi bi-chat'}`}></i>
        </div>
      </button>
      {/* <div class={`flex flex-col transition-all ease-in-out duration-500 ${showChat ? 'open' : 'closed'}`} > */}

      {/* <section class="py-5" style={{}}> */}
      <div id='chatBox' class={` ${showChat ? 'active-chatbox' : ''}  ${showChat && isDarkMode ? 'bx-shadow-sky' : 'bx-shadow'}`} style={{ width: '300px', height: '', position: 'fixed', bottom: 50, right: 30, borderRadius: '15px!important', zIndex: "12" }}>

        <div class="flex justify-center">
          <div class="w-full bg-white bx-shadow dark:bg-gray-800" style={{ borderRadius: '15px!important' }}>

            <div class="" id='chatbox-container' style={{ borderRadius: '15px!important', }}>
              <div class="flex  items-center p-3 bg-white dark:bg-gray-900 border-t-4 border-sky-400" style={{ borderRadius: '10px!important', }}>
                <img src="./images/calendar-icon.png" className='h-10 me-1' alt="" />
                <h5 class="font-semibold">GeniusCalendar</h5>
                <div class="flex items-center ms-auto">
                  {/* <span class="bg-yellow-400 text-white rounded-full p-1 text-xs mr-2">20</span> */}
                  <button id='minus-btn' className='' onClick={(event) => toggleChat(event)}
                  ><i class="fas fa-minus hover:text-sky-400  text-gray-500 text-xs mr-4"></i></button>
                  <button><i class="fas fa-comments hover:text-sky-400 text-gray-500 text-xs mr-4"></i></button>
                  <button id="times-closed" onClick={(event) => toggleChat(event)}
                  ><i class="fas fa-times hover:text-sky-400 text-gray-500 text-xs"></i></button>
                </div>
              </div>
              <div class="relative overflow-y-auto h-[350px] p-3">
                <div class="mb-4 ">
                  <div class="flex justify-between items-center mb-1">
                    <span class="text-xs font-semibold">Timona Siera</span>
                    <span class="text-xs text-gray-500">23 Jan 2:00 pm</span>
                  </div>
                  <div class="flex items-start ">
                    <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava5-bg.webp" alt="avatar" class="w-10 h-10 object-cover rounded-full" />
                    <p class="ml-2 p-2 rounded-lg bg-gray-200 dark:bg-gray-600 text-xs">For what reason would it be advisable for me to think about business content?</p>
                  </div>
                </div>

                <div class="mb-4">
                  <div class="flex justify-between items-center mb-1">
                    <span class="text-xs text-gray-500 ">23 Jan 2:05 pm</span>
                    <span class="text-xs font-semibold">Johny Bullock</span>
                  </div>
                  <div class="flex justify-end items-start">
                    <p class="mr-2 p-2 rounded-lg bg-sky-400 dark:bg-opacity-50 text-white text-xs">Thank you for your believe in our supports</p>
                    <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava6-bg.webp" alt="avatar" class="w-10 h-10 object-cover rounded-full" />
                  </div>
                </div>


              </div>
              <div class="bg-white dark:bg-gray-900 p-3" style={{ borderRadius: '15px!important' }}>
                <div class="flex justify-between items-center">
                  <input type="text" id="input-chatbox" class="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs h-8 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                  <button class="ml-2 px-3 py-1 rounded-full bg-sky-400 hover:bg-sky-600 text-white"><i className='fa fa-paper-plane'></i></button>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
      {/* </section> */}



      {/* </div> */}
    </div>
  )
}

export default ChatBox