import React from 'react';
import './CarouselInfinite.css';
import { useTranslation } from 'react-i18next';


function CarousselInfinite() {
  const { t } = useTranslation();

  const images = [
    'https://go.smartagenda.fr/_site/rdv-internet/logo-google-agenda.png',

    'https://logodownload.org/wp-content/uploads/2021/06/google-meet-logo.png',
    
    'https://logodownload.org/wp-content/uploads/2021/08/microsoft-teams-logo-2.png',
    
    
    'https://visio.univ-littoral.fr/wp-content/uploads/2020/10/zoom.png',
    
    'https://financesonline.com/uploads/2019/08/Microsoft-Outlook-logo1.png',
    
    'https://logos-marques.com/wp-content/uploads/2021/03/Gmail-Embleme.png',
    
    'https://logos-marques.com/wp-content/uploads/2021/03/Linkedin-logo-500x313.png',
    
    'https://logos-marques.com/stripe-logo/stripe-logo/',
    
    'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/PayPal.svg/2560px-PayPal.svg.png',
    
    // ... ajoutez plus d'URLs d'images ici
  ];
  const allImages = [...images, ...images, ...images, ...images];

  return (
    <div className="carousel-container">
       
        <h2 className='text-center font-bold text-2xl mb-20'>{t('Retrouver vos outils préférer et coordonnées les pour une')} <span className='text-blue-600 text-3xl'>{t('optimisation maximale')}</span> </h2>
        {/* <h2 className='text-center font-bold text-2xl mb-9'>Une planification simplifiée pour des <span className='text-blue-600 text-3xl'>millions d'utilisateurs</span> dans le monde</h2> */}
        <div className="carousel-content mb-9 flex items-center">
            {allImages?.map((src, index) => (
                <div className="carousel-item" key={index}>
                    <img src={src} alt={`carousel-item-${index}`} />
                </div>
            ))}
        </div>
    </div>
  );
}

export default CarousselInfinite;
