import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import NavbarDefault from '../Components/Navbar';
import { useTranslation } from 'react-i18next';

function PageNotFound() {
    const { t } = useTranslation();

  return (
    <div>
    {/* <NavbarDefault/> */}
<div class="flex flex-col justify-center items-center px-6 mx-auto h-screen xl:px-0 dark:bg-gray-900">
    <div class="block md:max-w-lg">
        <img src="/images/404.svg" alt="astronaut image"/>
    </div>
    <div class="text-center xl:max-w-4xl">
        <h1 class="mb-3 text-2xl font-bold leading-tight text-gray-900 sm:text-4xl lg:text-5xl dark:text-white">{t('Page non trouvée')}</h1>
        <p class="mb-5 text-base font-normal text-gray-500 md:text-lg dark:text-gray-400">{t('Oops ! On dirait que vous avez suivi un mauvais lien. Si vous pensez qu\'il s\'agit d\'un problème chez nous, veuillez nous le dire.')}</p>
        <Link to="/" className="transition duration-300 ease-in-out flex items-center justify-center bg-gradient-to-r from-sky-500 to-sky-700 hover:from-sky-600 hover:to-sky-800 focus:ring focus:ring-blue-200 text-white font-semibold rounded-lg px-6 py-3 text-sm w-1/3 mx-auto text-center">
  <i className='bi bi-arrow-left me-2'></i>
  {t('Retour à l\'accueil')}
</Link>

    </div>
</div>
</div>

  )
}

export default PageNotFound