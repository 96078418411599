import React, { useState, useRef, useEffect } from 'react';
import Modal from 'react-modal';
import html2pdf from 'html2pdf.js';
import InvoiceTemplate from './InvoiceTemplate';
import InvoiceForm from './InvoiceForm';
import Template1 from './Template1/Template1';
import ThemeCarousel from './ThemeCaroussel/ThemeCarousel';  // N'oubliez pas d'importer le composant
import introJs from 'intro.js';
import './LiveInvoiceEditor.css'

// Importer d'autres thèmes ici
import Template2 from './Template2/Template2';
import Template3 from './Template3/Template3';
// import Template3 from './Template3/Template3';
// Images pour le carrousel

function LiveInvoiceEditor() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const invoiceRef = useRef(null);
  const [invoiceData, setInvoiceData] = useState({ clientLastname: '', clientFirstname: '', date: new Date() });
  const [selectedTemplate, setSelectedTemplate] = useState("Template1");
  const [currentStep, setCurrentStep] = useState(1);  // Ajout de l'état currentStep
  const [editInRealTime, setEditInRealTime] = useState(true); // État pour activer/désactiver l'édition en temps réel
  const [showForm, setShowForm] = useState(false);
  const handlePrint = () => {
    window.print(); // Utiliser un gestionnaire d'événements pour l'impression
  };
  const downloadPdf = () => {
    // ... (Pas de changement ici)
  };
  const handleThemeChange = () => {
    setSelectedTemplate(null); // Réinitialiser le thème sélectionné
    setCurrentStep(1); // Revenir à l'étape 1
  };

  const onFieldChange = (key, value) => {
    setInvoiceData({
      ...invoiceData,
      [key]: value,
    });
  }  
  const renderTemplate = () => {
    switch (selectedTemplate) {
      case "Template1":
        return editInRealTime ? (
          <Template1
          invoiceData={invoiceData}
          onFieldChange={onFieldChange} />
        ) : (
          <Template1 invoiceData={invoiceData} />
        );
      case "Template2":
        return editInRealTime ? (
          <Template2
          invoiceData={invoiceData}
          onFieldChange={onFieldChange} />
        ) : (
          <Template2 invoiceData={invoiceData} />
        );
      case "Template3":
        return editInRealTime ? (
          <Template3
          invoiceData={invoiceData}
          onFieldChange={onFieldChange} />
        ) : (
          <Template3 invoiceData={invoiceData} />
        );
      // case "Template3":
      //   return <Template3 invoiceData={invoiceData} />;
      default:
        return null;
    }
  };

  return (
    <div className="live-invoice-editor">
    {currentStep === 1 && (
      <div>
        <h3>Étape 1: Choisissez un thème</h3>
        <ThemeCarousel setSelectedTemplate={template => {
          setSelectedTemplate(template);
          setCurrentStep(2);
        }} />
      </div>
    )}

    {currentStep === 2 && (
      <div>
        <h3>Étape 2: Remplissez la facture</h3>
        <button onClick={handleThemeChange}>Changer de thème</button>
        <label>
          <input
            type="checkbox"
            checked={showForm}
            onChange={() => setShowForm(!showForm)}
          />
          Édition en temps réel
        </label>
        {showForm && (
          <InvoiceForm invoiceData={invoiceData} setInvoiceData={setInvoiceData} onFieldChange={onFieldChange}/>
        )}
        <button onClick={() => setIsModalOpen(true)}>Vérifier la facture</button>
        <div ref={invoiceRef}>
          {renderTemplate()}
        </div>
        <button onClick={handlePrint}>Imprimer</button>
        <button onClick={downloadPdf}>Télécharger en PDF</button>
      </div>
    )}
  </div>
  );
}

export default LiveInvoiceEditor;
