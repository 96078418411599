import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = ({showSwitcherLanguage}) => {
  const { i18n } = useTranslation();
  const [menuToggle, setMenuToggle] = useState(false);
  const [selected, setSelected] = useState(0);
  // const [showSwitcherLanguage, setShowSwitcherLanguage] = useState(false);

  const countries = [
    { flag: '/images/united-states.png', label: 'English', lang: 'en' },
    { flag: '/images/france.png', label: 'Français', lang: 'fr' },
  ];
 // Réglage de la valeur par défaut de `selected`
 useEffect(() => {
  const defaultLangIndex = countries.findIndex((item) => item.lang === i18n.language);
  if (defaultLangIndex !== -1) {
    setSelected(defaultLangIndex);
  }
}, [i18n.language]);
  const handleClick = (index) => {
    setMenuToggle(false);
    setSelected(index);
    i18n.changeLanguage(countries[index].lang); // This changes the language
  };

  return (
    <div className={` flex justify-end ${showSwitcherLanguage ? '' : 'hidden'}`}>
      <div className="relative">
        <button
          className="bx-shadow justify-center bg-transparent flex text-gray-500 rounded shadow-lg py-2 px-3 focus:outline-none"
          onClick={() => setMenuToggle(!menuToggle)}
        >
  <img src={`${countries[selected].flag}`} className="h-5 w-5 flex justify-center items-center" alt="" />        </button>
        {menuToggle && (
          <div className="bg-white dark:bg-gray-800 text-gray-700 shadow-md rounded text-sm absolute mt-12 top-0 right-0 min-w-full w-48 z-30">
            <ul className="list-reset">
              {countries.map((item, index) => (
                <li key={index}>
                  <button
                    className="px-4 py-2 flex items-center dark:text-white hover:bg-gray-100  dark:hover:bg-gray-600 hover:rounded transition-colors duration-100 w-full text-left"
                    onClick={() => handleClick(index)}
                  >
                    <img src={`${item.flag}`} className="h-4 w-4 me-2" alt="" />
                    {item.label}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default LanguageSwitcher;
