import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';
import { LanguageProvider } from './Components/LanguageContext';
import { useDarkMode } from './Components/DarkModeContext';

import Home from './Screens/Home';
import Services from './Screens/Services';
import Price from './Screens/Price';
import Contact from './Screens/Contact';
import Footer from './Components/Footer';
import AdminFooter from './Components/Admin/AdminFooter';
import Dashboard from './DashboardScreens/Dashboard';
import Navbar from './Components/Navbar';
import DashboardNavbar from './Components/Admin/AdminNavbar';
import PageNotFound from './ErrorPages/PageNotFound';
import Informatique from './Screens/Informatique';
import Depannage from './Screens/Depannage';
import NewFeatures from './Screens/NewFeatures';

const MainLayout = ({ darkMode, toggleDarkMode }) => (
  <>
    <Navbar darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
    <main><Outlet /></main>
    <Footer />
  </>
);

const DashboardLayout = ({ darkMode, toggleDarkMode }) => (
  <>
    <DashboardNavbar darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
    <main><Outlet /></main>
    <AdminFooter />
  </>
);

function App() {
  const { darkMode, toggleDarkMode } = useDarkMode();

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainLayout darkMode={darkMode} toggleDarkMode={toggleDarkMode} />}>
          <Route index element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/solution/informatique" element={<Informatique />} />
          <Route path="/solution/depannage" element={<Depannage />} />
          <Route path="/nouveautes" element={<NewFeatures />} />
          <Route path="/tarifs" element={<Price />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
        <Route path="/dashboard/*" element={<DashboardLayout darkMode={darkMode} toggleDarkMode={toggleDarkMode} />}>
          <Route index element={<Dashboard />} />

        </Route>
        <Route path="*" element={<MainLayout darkMode={darkMode} toggleDarkMode={toggleDarkMode} />}>
          <Route index element={<PageNotFound />} />
        </Route>

      </Routes>
    </Router>
  );
}

export default App;
