import React, { useState, useRef, useEffect } from 'react';
import './AdminTabs.css'
import LiveInvoiceEditor from './Invoice/LiveInvoiceEditor';
function AdminTabs() {
  const [activeTab, setActiveTab] = useState('Tableau de bord');
  const tabListRef = useRef(null);

  useEffect(() => {
    const tabList = tabListRef.current;
    if (!tabList) return;
    
    const activeTabElement = tabList.querySelector('.active');
    if (activeTabElement) {
      activeTabElement.scrollIntoView({ inline: 'center', behavior: 'smooth' });
    }
  }, [activeTab]);

  return (
    <div className='max-w-screen-lg  mx-auto'>
      <div className=" text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700 ">
        <ul ref={tabListRef} className="flex  -mb-px overflow-x-auto whitespace-nowrap overflow-hidden-element">
          {['Tableau de bord', 'Profil',  'Paramètre', 'Contacts','Facture', 'Disabled'].map((tab) => (
            <li key={tab} className="mr-2">
              <a
                href="#"
                className={`inline-block p-4 border-b-2 rounded-t-lg ${
                  activeTab === tab ? 'text-blue-600 border-blue-600 active' : 'border-transparent'
                } ${tab === 'Disabled' ? 'text-gray-400 cursor-not-allowed' : ''}`}
                aria-current={activeTab === tab ? 'page' : undefined}
                onClick={(e) => {
                  e.preventDefault();
                  if (tab !== 'Disabled') {
                    setActiveTab(tab);
                  }
                }}
              >
                {tab}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="tab-content">
        {activeTab === 'Tableau de bord' && <div>Here is your dashboard content.</div>}
        {activeTab === 'Profil' && <div>Here is your profile content.</div>}
        {activeTab === 'Paramètre' && <div>Here are your settings.</div>}
        {activeTab === 'Contacts' && <div>Here are your contacts.</div>}
        {activeTab === 'Facture' && <div><LiveInvoiceEditor/></div>}
      </div>
     
    </div>
  );
}

export default AdminTabs;
